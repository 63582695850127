import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './Login.css'; // Assuming you have a CSS file for styles

function Login({ setUser }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [stage, setStage] = useState('email'); // initial stage
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://backend.snyp.co.za/api/check-email', { email });
      if (response.data.exists) {
        if (response.data.hasPassword) {
          setStage('login'); // move to login stage
        } else {
          setStage('setPassword'); // move to set password stage
        }
      } else {
        setError('Email not found');
      }
    } catch (error) {
      setError('Failed to check email');
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://backend.snyp.co.za/api/login', { email, password });
      const { token, firstname } = response.data;
      localStorage.setItem('token', token);
      setUser({ firstname });
      navigate('/userdashboard');
    } catch (error) {
      setError('Invalid email or password');
    }
  };

  const handleSetPasswordSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      const response = await axios.post('https://backend.snyp.co.za/api/set-password', { email, password });
      const { token, firstname } = response.data;
      localStorage.setItem('token', token);
      setUser({ firstname });
      navigate('/userdashboard');
    } catch (error) {
      setError('Failed to set password');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2 className='loginh2'>Fresha for customers</h2>
        <p className='loginp'>Create an account or log in to book and manage your appointments.</p>
        <button className="social-login facebook">Continue with Facebook (coming soon)</button>
        <button className="social-login google">Continue with Google (coming soon)</button>
        <div className="or-divider">OR</div>
        
        {stage === 'email' && (
          <form onSubmit={handleEmailSubmit}>
            <input className='logininput'
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="continue-btn">Continue</button>
          </form>
        )}

        {stage === 'login' && (
          <form onSubmit={handleLoginSubmit}>
            <input className='logininput'
              type="email"
              placeholder="Email"
              value={email}
              readOnly
            />
            <input className='logininput'
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit" className="continue-btn">Continue</button>
          </form>
        )}

        {stage === 'setPassword' && (
          <form onSubmit={handleSetPasswordSubmit}>
            <input className='logininput'
              type="email"
              placeholder="Email"
              value={email}
              readOnly
            />
            <input className='logininput'
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input className='logininput'
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button type="submit" className="continue-btn">Continue</button>
          </form>
        )}

        {error && <p className="errorlogin">{error}</p>}
        <p className='loginp'>
          Need an account? <a href="/register">Sign up now!</a>
        </p>
        <div className="support-links">
          <a href="/support">Support</a> | <a href="/language">English</a>
        </div>
      </div>
      <div className="login-image"></div>
    </div>
  );
}

export default Login;
