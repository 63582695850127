import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import successAnimation from '../MediaStores/complete.gif'; // Update the path to your animation file
import './Complete.css';

function Complete() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const {
    email,
    phone,
    firstName,
    lastName,
    businessName,
    professionalEmail,
    businessId,
    selectedDate,
    selectedTime,
    professionalName,
    professionalId,
    totalCost,
    duration,
    cart,
    paymentMethod // Add paymentMethod here
  } = state || {};

  const formattedDate = selectedDate ? moment(selectedDate).format('DD MMMM YYYY') : '';

  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // Show a confirmation dialog
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleLoginClick = () => {
    navigate('/login', { state: { email } }); // Pass email to Login component
  };

  return (
    <div className="confirmation-container">
      <div className="confirmation-card">
        <img src={successAnimation} alt="Success Animation" className="success-animation" />
        <h1>Booking Application Submitted</h1>
        <p>Your appointment application is still in progress. Please await confirmation. You will receive a confirmation email once your appointment is confirmed.</p>
        <div className="confirmation-details">
          <p><strong>Email:</strong> {email}</p>
          <p><strong>Phone:</strong> {phone}</p>
          <p><strong>Full Name:</strong> {firstName} {lastName}</p>
          <p><strong>Business:</strong> {businessName}</p>
          <p><strong>Date:</strong> {formattedDate} at {selectedTime}</p>
          <p><strong>Duration:</strong> {duration}</p>
          <p><strong>Professional:</strong> {professionalName}</p>
          <p><strong>Total Cost:</strong> R{totalCost} {paymentMethod}</p>
        </div>
        <h2>Services Booked:</h2>
        <div className="services-booked">
          {cart.map((item, index) => (
            <div key={index} className="service-item">
              <p><strong>Service Name:</strong> {item.name}</p>
              <p><strong>Price:</strong> R{item.price}</p>
              <p><strong>Duration:</strong> {item.duration} minutes</p>
            </div>
          ))}
        </div>
        <button className="login-button" onClick={handleLoginClick}>
          Log in to check your status
        </button>
      </div>
    </div>
  );
}

export default Complete;
