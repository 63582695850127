import React, { useState } from 'react';

const Yoco = () => {
  const [amount, setAmount] = useState('');

  const handlePayment = async () => {
    try {
      const amountInCents = Math.round(parseFloat(amount) * 100);
      const response = await fetch('https://backend.snyp.co.za/api/pay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          amount: amountInCents,
          currency: 'ZAR'
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error: ${response.status} - ${errorText}`);
      }

      const data = await response.json();
      console.log('Payment response:', data);

      if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
      }
    } catch (error) {
      console.error('Payment error:', error);
    }
  };

  return (
    <div>
      <h1>Yoco Payment</h1>
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter amount in Rands"
      />
      <button onClick={handlePayment}>Pay</button>
    </div>
  );
};

export default Yoco;
