import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Grid, Paper, Select, MenuItem, InputLabel, FormControl, Avatar } from '@mui/material';
import './Team.css'; // Import the CSS file

function generateTimeOptions() {
  const times = [];
  for (let hour = 6; hour <= 21; hour++) {
    times.push(`${hour.toString().padStart(2, '0')}:00:00`);
    times.push(`${hour.toString().padStart(2, '0')}:30:00`);
  }
  times.push("22:00:00");
  return times;
}

function Team() {
  const navigate = useNavigate();
  const location = useLocation();
  const { businessId } = location.state || {};

  const [newProfessional, setNewProfessional] = useState({
    firstname: '',
    lastname: '',
    email: '',
    contact: '',
    picture: '',
    startslot: '',
    endslot: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [professionals, setProfessionals] = useState([]);

  useEffect(() => {
    const fetchProfessionals = async () => {
      try {
        const response = await axios.get(`https://backend.snyp.co.za/api/business/${businessId}/professionals`);
        setProfessionals(response.data.professionals);
      } catch (error) {
        console.error('Error fetching professionals:', error);
      }
    };

    fetchProfessionals();
  }, [businessId]);

  const handleChange = (e) => {
    setNewProfessional({ ...newProfessional, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const registerResponse = await axios.post('https://backend.snyp.co.za/api/register-professional', newProfessional);

      if (registerResponse.data.success) {
        const professionalId = registerResponse.data.professionalId;

        const associateResponse = await axios.post('https://backend.snyp.co.za/api/associate-business-professional', {
          businessId,
          professionalId,
        });

        if (associateResponse.data.success) {
          window.location.reload();
        } else {
          console.error('Failed to associate professional with business');
        }
      } else {
        console.error('Failed to register new professional');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
    }
  };

  const timeOptions = generateTimeOptions();

  return (
    <Container className="container" maxWidth="sm">
      <Paper className="paper" elevation={3}>
        <Typography className="title" variant="h4" gutterBottom>
          Team Page
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Your business ID is: {businessId}
        </Typography>
        {errorMessage && (
          <Typography variant="body1" color="error" gutterBottom>
            {errorMessage}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} className="form-container">
            <Grid item xs={12}>
              <TextField
                name="firstname"
                value={newProfessional.firstname}
                onChange={handleChange}
                label="First Name"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="lastname"
                value={newProfessional.lastname}
                onChange={handleChange}
                label="Last Name"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                value={newProfessional.email}
                onChange={handleChange}
                label="Email"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="contact"
                value={newProfessional.contact}
                onChange={handleChange}
                label="Contact"
                fullWidth
                inputProps={{ maxLength: 10 }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="startslot-label">Start Time</InputLabel>
                <Select
                  labelId="startslot-label"
                  name="startslot"
                  value={newProfessional.startslot}
                  onChange={handleChange}
                  label="Start Time"
                >
                  {timeOptions.map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="endslot-label">End Time</InputLabel>
                <Select
                  labelId="endslot-label"
                  name="endslot"
                  value={newProfessional.endslot}
                  onChange={handleChange}
                  label="End Time"
                >
                  {timeOptions.map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="button-container">
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Add Professional
              </Button>
            </Grid>
            <Grid item xs={12} className="button-container">
              <Button variant="outlined" color="secondary" onClick={() => navigate("/dashboard")} fullWidth>
                Go Back Home
              </Button>
            </Grid>
          </Grid>
        </form>
        <Typography variant="h4" gutterBottom>
        Your Team
      </Typography>
      <div className="team-container">
        {professionals.map((professional) => (
          <div className="card" key={professional.id}>
            <div className="card-header">
              <img
                src={professional.picture}
                alt={professional.firstname}
                className="avatar"
              />
              <Typography variant="h6" className="name">
                {professional.firstname} {professional.lastname}
              </Typography>
            </div>
            <Typography variant="body2" className="email">
              {professional.email}
            </Typography>
          </div>
        ))}
      </div>
      </Paper>
    </Container>
  );
}

export default Team;
