import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './Tradinghours.css';

const days = [
  { name: 'Sunday', short: 'sun' },
  { name: 'Monday', short: 'mon' },
  { name: 'Tuesday', short: 'tue' },
  { name: 'Wednesday', short: 'wed' },
  { name: 'Thursday', short: 'thu' },
  { name: 'Friday', short: 'fri' },
  { name: 'Saturday', short: 'sat' },
];

const EditTradinghours = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { professionalId } = location.state || {};

  const [formData, setFormData] = useState({
    availability: days.reduce((acc, day) => {
      acc[day.short] = { available: 'True', start: '09:00', end: '17:00' };
      return acc;
    }, {}),
  });
console.log("Edit trading hour ID:", professionalId);
  const [tradingHours, setTradingHours] = useState(null);

  useEffect(() => {
    const fetchTradingHours = async () => {
      if (professionalId) {
        try {
          const response = await axios.get(`https://backend.snyp.co.za/api/get-trading-hours/${professionalId}`);
          setTradingHours(response.data.availability);
          console.log("api/get-trading-hours/",response.data.availability);
        } catch (error) {
          console.error('Error fetching trading hours:', error);
        }
      }
    };

    fetchTradingHours();
  }, [professionalId]);

  const handleCheckboxChange = (day) => {
    setFormData({
      ...formData,
      availability: {
        ...formData.availability,
        [day]: {
          ...formData.availability[day],
          available: formData.availability[day].available === 'True' ? 'False' : 'True',
        },
      },
    });
  };

  const handleTimeChange = (day, timeType, value) => {
    setFormData({
      ...formData,
      availability: {
        ...formData.availability,
        [day]: { ...formData.availability[day], [timeType]: value },
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!professionalId) {
      console.error('Professional ID is missing');
      return;
    }

    try {
      const response = await axios.post('https://backend.snyp.co.za/api/set-trading-hours', {
        professionalId,
        availability: formData.availability,
      });
      console.log('Trading hours set:', response.data);
      navigate('', { state: { professionalId } });
    } catch (error) {
      console.error('Error setting trading hours:', error);
    }
  };

  return (
    <div className="availability-container">
      <h2>Set Trading Hours</h2>
      <form onSubmit={handleSubmit}>
        <h3>Set Availability</h3>
        {days.map((day) => (
          <div className="day-container" key={day.short}>
            <input
              type="checkbox"
              id={day.short}
              checked={formData.availability[day.short].available === 'True'}
              onChange={() => handleCheckboxChange(day.short)}
            />
            <label htmlFor={day.short}>{day.name}</label>
            <input
              type="time"
              value={formData.availability[day.short].start}
              onChange={(e) =>
                handleTimeChange(day.short, 'start', e.target.value)
              }
            />
            <input
              type="time"
              value={formData.availability[day.short].end}
              onChange={(e) =>
                handleTimeChange(day.short, 'end', e.target.value)
              }
            />
          </div>
        ))}
        <button type="submit">Save Trading Hours</button>
      </form>

      {tradingHours && (
        <div className="trading-hours-display">
          <h3>Current Trading Hours</h3>
          {days.map((day) => (
            <div key={day.short} className="day-display">
              <strong>{day.name}:</strong>{' '}
              {tradingHours[day.short].available === 'True'
                ? `${tradingHours[day.short].start} - ${tradingHours[day.short].end}`
                : 'Not available'}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EditTradinghours;
