import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Business() {
  const navigate = useNavigate();
  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const response = await axios.get('https://backend.snyp.co.za/api/get-businesses');
        setBusinesses(response.data);
      } catch (error) {
        console.error('Failed to fetch business data:', error);
      }
    };

    fetchBusinessData();
  }, []);

  // Function to handle click on a business
  const handleBusinessClick = (businessId) => {
    // Navigate to Professional.js and pass the selected business's ID
    navigate(`/professional/${businessId}`);
  };



  return (
    <div>
      <h2>Business Page</h2>
      <div>
        {businesses.map((business) => (
          <div key={business.businessid} style={{ marginBottom: '20px', cursor: 'pointer' }} onClick={() => handleBusinessClick(business.businessid)}>
            <img src={business.imageurl} alt={business.name} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
            <h3>{business.name}</h3>
            <p>Address: {business.address}</p>
            <p>Category: {business.category}</p>
          </div>
        ))}
      </div>
      <button onClick={() => navigate('/')}>Go Back Home</button>
    </div>
  );
}

export default Business;
