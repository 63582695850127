// interact with google calendar

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import {jwtDecode} from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faPlus, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import './Dashboard.css';
import Preloader from './Preloader.js';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS


function Dashboard() {
  const navigate = useNavigate();
  const [professionalId, setProfessionalId] = useState(null);
  const [businessId, setBusinessId] = useState(null);
  const [businessName, setBusinessName] = useState('');
  const [professionalDetails, setProfessionalDetails] = useState({});
  const [bookedSlots, setBookedSlots] = useState([]);
  const [weeklyCashoutData, setWeeklyCashoutData] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    sales: 2382,
    earnings: 21300,
    visitors: 14212,
    orders: 64,
    salesChange: -3.85,
    earningsChange: 8.85,
    visitorsChange: 5.25,
    ordersChange: -2.25
  });
  const [loading, setLoading] = useState({
    professionalDetails: true,
    businessName: true,
    bookedSlots: true,
    weeklyCashoutData: true
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    const decoded = jwtDecode(token);
    setProfessionalId(decoded.id);
    console.log("id from jwt:", decoded.id);

    const fetchBusinessId = async () => {
      try {
        const response = await axios.get(`https://backend.snyp.co.za/api/get-ids/${decoded.id}`);
        if (response.data.success) {
          setBusinessId(response.data.businessId);
        } else {
          console.error('Failed to fetch business ID:', response.data.message);
        }
      } catch (error) {
        console.error('Failed to fetch business ID:', error);
      }
    };

    fetchBusinessId();
  }, [navigate]);

  useEffect(() => {
    const fetchBusinessName = async () => {
      if (!businessId) return;
      try {
        setLoading(prev => ({ ...prev, businessName: true }));
        const response = await axios.get(`https://backend.snyp.co.za/api/business/${businessId}`);
        setBusinessName(response.data.name);
        setLoading(prev => ({ ...prev, businessName: false }));
      } catch (error) {
        console.error(`Failed to fetch business name for ID: ${businessId}:`, error);
        setLoading(prev => ({ ...prev, businessName: false }));
      }
    };

    fetchBusinessName();
  }, [businessId]);

  useEffect(() => {
    const fetchProfessionalDetails = async () => {
      if (!professionalId) return;
      try {
        setLoading(prev => ({ ...prev, professionalDetails: true }));
        const response = await axios.get(`https://backend.snyp.co.za/api/professionals/${professionalId}`);
        setProfessionalDetails(response.data);
        setLoading(prev => ({ ...prev, professionalDetails: false }));
      } catch (error) {
        console.error(`Failed to fetch professional details for ID: ${professionalId}:`, error);
        setLoading(prev => ({ ...prev, professionalDetails: false }));
      }
    };

    fetchProfessionalDetails();
  }, [professionalId]);

  useEffect(() => {
    const fetchBookedSlots = async () => {
      if (!professionalId) return;
      try {
        setLoading(prev => ({ ...prev, bookedSlots: true }));
        console.log("id from booked slots:", professionalId);
        const { data } = await axios.get(`https://backend.snyp.co.za/api/booked-slots/${professionalId}`);
        setBookedSlots(data);
        setProfessionalId(professionalId);
        setLoading(prev => ({ ...prev, bookedSlots: false }));
      } catch (error) {
        console.error(`Failed to fetch booked slots for professional ID: ${professionalId}:`, error);
        setLoading(prev => ({ ...prev, bookedSlots: false }));
      }
    };

    fetchBookedSlots();

    const interval = setInterval(fetchBookedSlots, 3600000); // Update hourly
    return () => clearInterval(interval);
  }, [professionalId]);

  useEffect(() => {
    const fetchWeeklyCashoutData = async () => {
      if (!professionalId) return;
      try {
        setLoading(prev => ({ ...prev, weeklyCashoutData: true }));
        const response = await axios.get(`https://backend.snyp.co.za/api/get-weekly-total-sums/${professionalId}`);
        setWeeklyCashoutData(response.data.data);
        setLoading(prev => ({ ...prev, weeklyCashoutData: false }));
      } catch (error) {
        console.error('Failed to fetch weekly cashout data:', error);
        setLoading(prev => ({ ...prev, weeklyCashoutData: false }));
      }
    };

    fetchWeeklyCashoutData();
  }, [professionalId]);

  const today = moment().startOf('day');
  const currentTime = moment();
 // Today's Appointments
const todaysAppointments = bookedSlots.filter(appointment => 
  moment(appointment.date).isSame(today, 'day')
);

// Past Appointments
const pastAppointments = bookedSlots.filter(appointment => 
  moment(appointment.date).isBefore(today)
);

// Upcoming Appointments
const upcomingAppointments = bookedSlots.filter(appointment => 
  moment(appointment.date).isAfter(today)
);

  const handleConfirm = async (id) => {
    try {
      const payload = {
        professionalId: professionalId,
      };
      await axios.post(`https://backend.snyp.co.za/api/appointments/${id}/confirm`, payload);
      const updatedSlots = bookedSlots.map(slot => slot.id === id ? { ...slot, status: 'Confirmed' } : slot);
      setBookedSlots(updatedSlots);
      const confirmedAppointment = bookedSlots.find(slot => slot.id === id);
      console.log('Confirmed Appointment:', confirmedAppointment);
      await addCashout(confirmedAppointment, professionalId);
    } catch (error) {
      console.error(`Failed to confirm appointment ID: ${id}:`, error);
    }
  };
  

 
  const handleDecline = async (id) => {
    confirmAlert({
      title: 'Customer no show?',
      message: 'Are you sure you want to remove this appointment?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await axios.post(`https://backend.snyp.co.za/api/appointments/${id}/decline`);
              setBookedSlots(bookedSlots.filter(slot => slot.id !== id)); // Remove declined appointments from list
            } catch (error) {
              console.error(`Failed to decline appointment ID: ${id}:`, error);
            }
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };
  useEffect(() => {
    console.log("Professional ID updated:", professionalId);
  }, [professionalId]);
  
  const addCashout = async (appointment, professionalId) => {
    if (!appointment) return;
    const { id, total_cost, professional_name, payment_method, status } = appointment;
  
    // Add a console log to display the professionalId and appointment ID
    console.log('Appointment ID:', id);
    console.log('Professional ID:', professionalId);
  
    if (status === 'Confirmed') {
      try {
        const payload = {
          professionalId: professionalId,
          remainingAmount: total_cost,
          serviceName: professional_name
        };
        
        console.log('Payload being sent:', payload);
        
        if (payment_method === 'Card') {
          await axios.post('https://backend.snyp.co.za/api/update-card-sum', payload);
        } else if (payment_method === 'Cash') {
          await axios.post('https://backend.snyp.co.za/api/update-cash-sum', payload);
        }
        
      } catch (error) {
        console.error('Failed to add cashout:', error);
      }
    }
  };
  

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Dashboard</h1>
      </div>
      <div className="main-content">
        <div className="content">
          <div className="welcome-container">
            {loading.professionalDetails ? (
              <Preloader />
            ) : (
              <>
                {professionalDetails.picture && (
                  <img src={professionalDetails.picture} alt="Professional" className="profile-picture" />
                )}
                <div className="flex-container">
                  {businessName && <h3><i className="fas fa-home"></i> {businessName}</h3>}
                  <h3><i className="fa-solid fa-user"></i> {professionalDetails.firstname} {professionalDetails.lastname}</h3>
                  <h3><i className="fa-solid fa-envelope"></i> {professionalDetails.email}</h3>
                  <h3><i className="fa-solid fa-phone"></i> {professionalDetails.contact}</h3>
                  <h3><i className="fa-solid fa-clock"></i> {professionalDetails.startslot} - {professionalDetails.endslot}</h3>
                </div>
              </>
            )}
          </div>
          <div className="cashout-info">
            <h3>Weekly Cashout Information</h3>
            {loading.weeklyCashoutData ? (
              <Preloader />
            ) : (
              weeklyCashoutData.length > 0 ? (
                <ul className="cashout-list">
                  {weeklyCashoutData.map((week, index) => (
                    <li key={index} className="cashout-item">
                      <div className="cashout-week">
                        <span>Week Starting: {week.week_start}</span>
                      </div>
                      <div className="metrics">
                        <div className="metric-card">
                          <h3>Earnings (Card)</h3>
                          <p>R{parseFloat(week.total_card_sum).toFixed(0)}</p>
                        </div>
                        <div className="metric-card">
                          <h3>Earnings (Cash)</h3>
                          <p>R{parseFloat(week.total_cash_sum).toFixed(0)}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No weekly cashout data available.</p>
              )
            )}
          </div>
          <div className="actions">      
            <button onClick={() => navigate('/add-business', { state: { businessId, professionalId } })}>
              <FontAwesomeIcon icon={faBriefcase} /> Add Your Business 
            </button> 
            <button onClick={() => navigate('/add-service', { state: { businessId, professionalId } })}>
              <FontAwesomeIcon icon={faPlus} /> Add Your Service
            </button>
            <button onClick={() => navigate('/Team', { state: { businessId, professionalId } })}>
              <FontAwesomeIcon icon={faUsers} /> Add Your Team
            </button>
            <button onClick={() => navigate('/EditSchedule', { state: { businessId, professionalId } })}>
              <FontAwesomeIcon icon={faUsers} /> Edit Schedule
            </button>
          </div>
          <div className="appointments">
            <h3>Today's Appointments</h3>
            {loading.bookedSlots ? (
              <Preloader />
            ) : ( 
              todaysAppointments.length > 0 ? (
                <ul className="appointment-list">
                  {todaysAppointments.map((appointment, index) => {
                    const formattedDate = moment(appointment.date).format('ddd, D MMM YYYY');
                    const formattedTime = moment(appointment.slot, 'HH:mm:ss').format('HH:mm');
                    const { id, first_name, last_name, professional_name, service_details, total_cost, duration, status, payment_method } = appointment;
                    const services = JSON.parse(service_details);

                    return (
                      <li key={index} className="appointment-item">
                        <div className="appointment-details">
                          <div className="appointment-header">
                            <div className="">
                              <span>{formattedDate} {formattedTime}</span>
                            </div>
                            {payment_method && <span className={`appointment-status ${payment_method.toLowerCase()}`}>
                              {payment_method}</span>}
                          </div>
                          <div className="appointment-info">
                            {services.map((service, idx) => (
                              <div key={idx}>
                                <h4>{service.name}</h4>
                                <p>{`${first_name} ${last_name}, ${duration} min, R${parseFloat(total_cost).toFixed(2)}`}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="appointment-actions">
                          {status === 'Confirmed' && (
                            <button className="decline-button" onClick={() => handleDecline(id)}>no show</button>
                          )}
                          {status === 'Booked' && (
                            <>
                              <button className="confirm-button" onClick={() => handleConfirm(id)}>Confirm</button>
                              <button className="decline-button" onClick={() => handleDecline(id)}>Decline</button>
                            </>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p>No appointments available for today.</p>
              )
            )}
          </div>
          <div className="appointments">
            <h3>Upcoming Appointments</h3>
            {loading.bookedSlots ? (
              <Preloader />
            ) : (
              upcomingAppointments.length > 0 ? (
                <ul className="appointment-list">
                  {upcomingAppointments.map((appointment, index) => {
                    const formattedDate = moment(appointment.date).format('ddd, D MMM YYYY');
                    const formattedTime = moment(appointment.slot, 'HH:mm:ss').format('HH:mm');
                    const { id, first_name, last_name, professional_name, service_details, total_cost, duration, status, payment_method } = appointment;
                    const services = JSON.parse(service_details);

                    return (
                      <li key={index} className="appointment-item">
                        <div className="appointment-details">
                          <div className="appointment-header">
                            <div className="appointment-date">
                              <span>{moment(appointment.date).format('D')}</span>
                              <span>{moment(appointment.date).format('MMM')}</span>
                            </div>
                            <span className="appointment-datelong">{formattedDate} {formattedTime}</span>
                            {payment_method && <span className={`appointment-status ${payment_method.toLowerCase()}`}>
                              {payment_method}</span>}
                          </div>
                          <div className="appointment-info">
                            {services.map((service, idx) => (
                              <div key={idx}>
                                <h4>{service.name}</h4>
                                <p>{`${first_name} ${last_name}, ${duration} min, R${parseFloat(total_cost).toFixed(2)}`}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="appointment-actions">
                          {status === 'Confirmed' && (
                            <button className="decline-button" onClick={() => handleDecline(id)}>no show</button>
                          )}
                          {status === 'Booked' && (
                            <>
                              <button className="confirm-button" onClick={() => handleConfirm(id)}>Confirm</button>
                              <button className="decline-button" onClick={() => handleDecline(id)}>Decline</button>
                            </>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p>No upcoming appointments available.</p>
              )
            )}
          </div>
          <div className="appointments">
            <h3>Past Appointments</h3>
            {loading.bookedSlots ? (
              <Preloader />
            ) : (
              pastAppointments.length > 0 ? (
                <ul className="appointment-list">
                  {pastAppointments.map((appointment, index) => {
                    const formattedDate = moment(appointment.date).format('ddd, D MMM YYYY');
                    const formattedTime = moment(appointment.slot, 'HH:mm:ss').format('HH:mm');
                    const { id, first_name, last_name, professional_name, service_details, total_cost, duration, status, payment_method } = appointment;
                    const services = JSON.parse(service_details);

                    return (
                      <li key={index} className="appointment-item">
                        <div className="appointment-date">
                          <span>{moment(appointment.date).format('D')}</span>
                          <span>{moment(appointment.date).format('MMM')}</span>
                        </div>
                        <div className="appointment-details">
                          <div className="appointment-header">
                            <span>{formattedDate} {formattedTime}</span>
                            {status && <span className={`appointment-status ${status.toLowerCase()}`}>{status}</span>}
                          </div>
                          <div className="appointment-info">
                            {services.map((service, idx) => (
                              <div key={idx}>
                                <h4>{service.name}</h4>
                                <p>{service.description}</p>
                                <p>Price: ZAR {service.price}</p>
                                <p>Duration: {service.duration} min</p>
                              </div>
                            ))}
                            <p>{`${first_name} ${last_name}, ${duration} min, R${parseFloat(total_cost).toFixed(2)}`}</p>
                          </div>
                        </div>
                        <div className="appointment-cost">
                          <span>ZAR {parseFloat(total_cost).toFixed(2)}</span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p>No past appointments available.</p>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
