import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function Success() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = location.state;

  const email = queryParams.get('email') || state?.email;
  const phone = queryParams.get('phone') || state?.phoneNumber;
  const firstName = queryParams.get('firstName') || state?.firstName;
  const lastName = queryParams.get('lastName') || state?.lastName;
  const businessName = queryParams.get('businessName') || state?.businessName;
  const professionalEmail = queryParams.get('professionalEmail') || state?.professionalEmail;
  const businessId = queryParams.get('businessId') || state?.businessId;
  const selectedDate = queryParams.get('selectedDate') || state?.selectedDay;
  const selectedTime = queryParams.get('selectedTime') || state?.selectedTime;
  const professionalName = queryParams.get('professionalName') || state?.professionalName;
  const professionalId = queryParams.get('professionalId') || state?.professionalId;
  const totalCost = parseFloat(queryParams.get('totalCost') || state?.totalCost || '0.00').toFixed(2);
  const duration = queryParams.get('totalDuration') || state?.totalDuration;
  const cart = state?.cart || JSON.parse(decodeURIComponent(queryParams.get('cart') || '[]'));
  const paymentType = queryParams.get('paymentType') || state?.paymentType || 'card';

  useEffect(() => {
    const postData = {
      email,
      phone,
      firstName,
      lastName,
      businessId,
      businessName,
      professionalEmail,
      selectedDate,
      selectedTime,
      professionalName,
      professionalId,
      cart,
      totalCost,
      duration,
      paymentType
    };

    if (paymentType === 'cash') {
      navigate('/cash', { state: postData, replace: true }); // Navigate to Cash with state data
    } else {
      navigate('/card', { state: postData, replace: true }); // Navigate to Card with state data
    }

    // Remove Success page from history stack
    window.history.replaceState(null, document.title, window.location.href);
  }, [navigate, paymentType]);

  return (
    <div>
      <h1>Redirecting...</h1>
    </div>
  );
}

export default Success;
