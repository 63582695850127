import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ClientOrBusiness.css';
import backgroundImage from '../MediaStores/Image2-b598cd77921dc7f9a62fb3ec31bcaa48.webp'; // Adjust the path to your image file

const ClientOrBusiness = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="client-or-business-container" style={{margin: '20px'}}>
      <div className="left-section">
        <button className="back-button" onClick={() => navigate(-1)}>&larr;</button>
     
        <div className="options">   <h1 className='cobh1'>Sign up/log in</h1>
          <button className="option-button" onClick={() => handleNavigate('/login')}>
            <div className="option-text">
              <h2 className='cobh2'>For everyone</h2>
              <p>Book salons and spas near you</p>
            </div>
            <span className="arrowbutton">&rarr;</span>
          </button>
          <button className="option-button" onClick={() => handleNavigate('/professional_login')}>
            <div className="option-text">
              <h2 className='cobh2'>For business</h2>
              <p>Manage and grow your business</p>
            </div>
            <span className="arrowbutton">&rarr;</span>
          </button>
        </div>
        <div className="footer">
          <button className="footer-button">English</button>
          <button className="footer-button">Support</button>
        </div>
      </div>
      <div className="right-section">
        <img src={backgroundImage} alt="Background" className="background-image" />
      </div>
    </div>
  );
};

export default ClientOrBusiness;
