import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios

function CheckEmail() {
  let navigate = useNavigate();
  const [email, setEmail] = useState('');

  const checkEmailInDatabase = async (e) => {
    e.preventDefault(); // Prevent the form from causing a page reload

    try {
      // Use axios for the POST request
      const response = await axios.post('https://backend.snyp.co.za/api/check-email', {
        email: email
      });

      // With axios, the data property directly contains the response body
      if (response.data.exists) {
        navigate('/login', { state: { email } });
      } else {
        navigate('/register', { state: { email } });
      }
    } catch (error) {
      console.error('Error:', error);
      // Optionally, handle the error more gracefully here
    }
  };

  return (
    <div>
      <h2>Check Email</h2>
      <form onSubmit={checkEmailInDatabase}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <button type="submit">Check Email</button>
      </form>
      <button onClick={() => navigate('/')}>Go Back Home</button>
    </div>
  );
}

export default CheckEmail;
