import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './addservice.css';

function AddService() {
  const navigate = useNavigate();
  const location = useLocation();
  const { professionalId,businessId } = location.state || {};
  const [serviceData, setServiceData] = useState({
    serviceName: '',
    serviceType: '',
    serviceCategory: '',
    serviceDescription: '',
    serviceDuration: '',
    price: '',
    serviceAvailable: '',
    discount: '',
  });
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`https://backend.snyp.co.za/api/get-services/${professionalId}`);
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, [professionalId]);

  const handleChange = (e) => {
    setServiceData({ ...serviceData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const serviceToSubmit = {
      ...serviceData,
      professionalId,
    };

    try {
      const response = await axios.post('https://backend.snyp.co.za/api/add-service', serviceToSubmit);

      if (response.data.success) {
        alert('Service added successfully!');
        setServiceData({
          serviceName: '',
          serviceType: '',
          serviceCategory: '',
          serviceDescription: '',
          serviceDuration: '',
          price: '',
          serviceAvailable: '',
          discount: '',
        });
        // Fetch the updated list of services
        const updatedServices = await axios.get(`https://backend.snyp.co.za/api/get-services/${professionalId}`);
        setServices(updatedServices.data);
      } else {
        alert('Failed to add service. Please try again.');
      }
    } catch (error) {
      console.error('Error adding service:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleDelete = async (serviceId) => {
    if (window.confirm('Are you sure you want to delete this service?')) {
      try {
        const response = await axios.delete(`https://backend.snyp.co.za/api/delete-service/${serviceId}`);
        if (response.data.success) {
          alert('Service deleted successfully!');
          setServices(services.filter(service => service.serviceid !== serviceId));
        } else {
          alert('Failed to delete service. Please try again.');
        }
      } catch (error) {
        console.error('Error deleting service:', error);
        alert('An error occurred. Please try again.');
      }
    }
  };

  const renderDurationOptions = () => {
    const options = [];
    for (let i = 30; i <= 240; i += 15) {
      options.push(<option key={i} value={i}>{i} minutes</option>);
    }
    return options;
  };

  const navigateToTeam = () => {
    navigate('/Team', { state: { businessId, professionalId } });
  };

  return (
    <div className="add-service-container">
      <h2>Add a new single service</h2>
      <form onSubmit={handleSubmit} className="service-form">
        <div className="form-group">
          <label>Service name</label>
          <input
            name="serviceName"
            value={serviceData.serviceName}
            onChange={handleChange}
            placeholder="Service Name"
            required
          />
        </div>
        <div className="form-group">
          <label>Service type</label>
          <select
            name="serviceType"
            value={serviceData.serviceType}
            onChange={handleChange}
            required
          >
            <option value="">Select Service Type</option>
            <option value="Haircut">Haircut</option>
            <option value="Nail Care">Nail Care</option>
            <option value="Facial Treatment">Facial Treatment</option>
            <option value="Massage">Massage</option>
            <option value="Makeup">Makeup</option>
          </select>
        </div>
        <div className="form-group">
          <label>Service category</label>
          <select
            name="serviceCategory"
            value={serviceData.serviceCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select Service Category</option>
            <option value="kids">Kids</option>
            <option value="men">Men</option>
            <option value="women">Women</option>
          </select>
        </div>
        <div className="form-group">
          <label>Service description</label>
          <textarea
            name="serviceDescription"
            value={serviceData.serviceDescription}
            onChange={handleChange}
            placeholder="Add a short description"
            maxLength="1000"
            required
          />
        </div>
        <div className="form-group">
          <label>Service duration</label>
          <select
            name="serviceDuration"
            value={serviceData.serviceDuration}
            onChange={handleChange}
            required
          >
            <option value="">Select Duration</option>
            {renderDurationOptions()}
          </select>
        </div>
        {/* <div className="form-group">
          <label>Service available for</label>
          <input
            name="serviceAvailable"
            value={serviceData.serviceAvailable}
            onChange={handleChange}
            placeholder="Service Available"
            required
          />
        </div> */}
        <div className="form-group">
          <label>Price</label>
          <input
            name="price"
            value={serviceData.price}
            onChange={handleChange}
            placeholder="Price"
            required
            type="number"
          />
        </div>
        <div className="form-group">
          <label>Discount (%)</label>
          <input
            name="discount"
            value={serviceData.discount}
            onChange={handleChange}
            placeholder="Discount Percentage"
            type="number"
            min="0"
            max="100"
            required
          />
        </div>
        <button className='addservicebutton' type="submit">Add Service</button>
      </form>

      <h3 className="addserviceh3">Services menu</h3>
      {services.length > 0 ? (
        <ul className='addserviceul'>
          {services.map(service => (
            <li className='addserviceli' key={service.serviceid}>
              {service.name} - {service.duration}mins - R{service.price} - type: {service.type}({service.category})
              <button className='addservicebutton edit' onClick={() => navigate(`/edit-service/${service.serviceid}`, { state: { serviceId: service.serviceid } })}>
                Edit
              </button>
              <button className='addservicebutton remove' onClick={() => handleDelete(service.serviceid)}>Remove</button>
 
            </li>
          ))}
        </ul>
      ) : (
        <p>No services found.</p>
      )}

      <div className="button-container">
        <button className='addservicebutton' onClick={() => navigate(-1)}>Go Back Home</button>
        <button className='addservicebutton' onClick={navigateToTeam}>Go to Team <i className="fa-solid fa-arrow-right"></i></button>
      </div>
    </div>
  );
}

export default AddService;
