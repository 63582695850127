import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './Payment.css';

function Card() {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  useEffect(() => {
    const addUserAndBookAppointment = async () => {
      try {
        // Check and add user
        await axios.post('https://backend.snyp.co.za/api/check-and-add-user', {
          email: state.email,
          phone: state.phoneNumber,
          firstname: state.firstName,
          lastname: state.lastName
        });

        // Book appointment
        const response = await axios.post('https://backend.snyp.co.za/api/book-appointment', {
          ...state,
          paymentMethod: 'card'
        });
        console.log(response.data);
        navigate('/complete', { state: { ...state, paymentMethod: 'card' } }); // Navigate to Complete with state data
      } catch (error) {
        console.error('Failed to book appointment:', error);
      }
    };

    addUserAndBookAppointment();
  }, [navigate, state]);

  // useEffect(() => {
  //   if (state) {
  //     state.cart.forEach(async item => {
  //       const separatedAmount = 0;
  //       const sep = item.price - separatedAmount;
  //       const remainingAmount = sep / 2;

  //       // const separatedAmount = 10;
  //       // const sep = item.price - separatedAmount;
  //       // const remainingAmount = sep / 2;
  //       console.log(`Service: ${item.name}, Separated Amount: R${separatedAmount}, Remaining Amount: R${remainingAmount}`);

  //       try {
  //         await axios.post('https://backend.snyp.co.za/api/update-card-sum', {
  //           professionalId: state.professionalId,
  //           remainingAmount,
  //           serviceName: item.name
  //         });
  //       } catch (error) {
  //         console.error('Failed to update card sum:', error);
  //       }
  //     });
  //   }

  //   // Prevent going back to Success page
  //   window.history.replaceState(null, document.title, window.location.href);
  // }, [state]);

  return (
    <div className="payment-container">
      <h1>Please wait while we book your appointment</h1>
      <FontAwesomeIcon icon={faSpinner} spin size="3x" />
    </div>
  );
}

export default Card;
