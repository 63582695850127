import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './AddBusiness.css';

function AddBusiness() {
  const navigate = useNavigate();
  const location = useLocation();
  const { professionalId, businessId } = location.state || {};
  console.log("my prof id:", professionalId);

  const [businessData, setBusinessData] = useState({
    name: '',
    address: '',
    category: '',
    longitude: '', 
    latitude: '',
    imageurl: ''
  });

  const [businesses, setBusinesses] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const response = await axios.get(`https://backend.snyp.co.za/api/businesses-by-professional/${professionalId}`);
        setBusinesses(response.data);
        if (response.data.length > 0) {
          // Set the first business as the default to edit
          const existingBusiness = response.data[0];
          setBusinessData({
            name: existingBusiness.name,
            address: existingBusiness.address,
            category: existingBusiness.category,
            longitude: existingBusiness.longitude,
            latitude: existingBusiness.latitude,
            imageurl: existingBusiness.imageurl
          });
        }
      } catch (error) {
        console.error('Error fetching businesses:', error);
      }
    };

    fetchBusinesses();
  }, [professionalId]);

  const handleAddressChange = (e) => {
    const inputValue = e.target.value;
    setBusinessData(prevData => ({ ...prevData, address: inputValue }));

    if (inputValue.length > 2) {
      axios.get(`https://api.geoapify.com/v1/geocode/autocomplete?text=${inputValue}&apiKey=c86269d939f84d3980a3c26589aede68`)
        .then(response => {
          setSuggestions(response.data.features);
        })
        .catch(error => {
          console.error('Error fetching geolocation data:', error);
        });
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setBusinessData(prevData => ({
      ...prevData,
      address: suggestion.properties.formatted,
      latitude: suggestion.geometry.coordinates[1],
      longitude: suggestion.geometry.coordinates[0]
    }));
    setSuggestions([]);
  };

  const handleChange = (e) => {
    setBusinessData(prevData => ({ ...prevData, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');

    try {
      let response;
      if (businesses.length > 0) {
        // If a business exists, update it
        response = await axios.put(`https://backend.snyp.co.za/api/update-business/${businesses[0].businessid}`, {
          ...businessData,
          professionalId,
        });
      } else {
        // If no business exists, create a new one
        response = await axios.post('https://backend.snyp.co.za/api/add-business', {
          ...businessData,
          professionalId,
        });
      }

      if (response.data.success) {
        console.log('Business saved successfully');
        window.location.reload();
      } else {
        console.error('Failed to save business');
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error saving business:', error);
      if (error.response && error.response.status === 409) {
        setErrorMessage('Business already exists');
      } else {
        setErrorMessage('Internal server error');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const navigateToAddService = () => {
    navigate('/add-service', { state: { businessId, professionalId } });
  };

  return ( 
    <div className="container">
      <h2 className="title">Business Information</h2>
      
      {businesses.length > 0 ? (
        <div>
          <h3 className="subtitle">Edit Existing Business</h3>
          <form className="form" onSubmit={handleSubmit}>
            <input
              name="name"
              value={businessData.name}
              onChange={handleChange}
              placeholder="Business Name"
              required
              className="input"
            />
            <input
              name="address"
              value={businessData.address}
              onChange={handleAddressChange}
              placeholder="Business Address"
              required
              className="input"
            />
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.properties.formatted}
                </li>
              ))}
            </ul>
            <select
              name="category"
              value={businessData.category}
              onChange={handleChange}
              required
              className="select"
            >
              <option value="">Select a Category</option>
              <option value="Hair Salon">Hair Salon</option>
              <option value="Nail Salon">Nail Salon</option>
              <option value="Spa">Spa</option>
              <option value="Barbershop">Barbershop</option>
            </select>
            <div className="image-preview">
              {businessData.imageurl && (
                <img src={businessData.imageurl} alt={businessData.name} />
              )}
            </div>
            <button type="submit" className="button" disabled={isSubmitting}>
              {isSubmitting ? 'Saving...' : 'Save Changes'}
            </button>
          </form>
        </div>
      ) : (
        <div>
          <h3 className="subtitle">Add New Business</h3>
          <form className="form" onSubmit={handleSubmit}>
            <input
              name="name"
              value={businessData.name}
              onChange={handleChange}
              placeholder="Business Name"
              required
              className="input"
            />
            <input
              name="address"
              value={businessData.address}
              onChange={handleAddressChange}
              placeholder="Business Address"
              required
              className="input"
            />
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.properties.formatted}
                </li>
              ))}
            </ul>
            <select
              name="category"
              value={businessData.category}
              onChange={handleChange}
              required
              className="select"
            >
              <option value="">Select a Category</option>
              <option value="Hair Salon">Hair Salon</option>
              <option value="Nail Salon">Nail Salon</option>
              <option value="Spa">Spa</option>
              <option value="Barbershop">Barbershop</option>
            </select>
            <div className="image-preview">
              {businessData.imageurl && (
                <img src={businessData.imageurl} alt={businessData.name} />
              )}
            </div>
            <button type="submit" className="button" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </form>
        </div>
      )}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      
      <h2 className="title">Existing Businesses</h2>
      <ul className="business-list">
        {businesses.map((business) => (
          <li key={business.businessid} className="business-item">
            {business.name} - {business.address} - {business.category}
             {/* - {business.latitude} - {business.longitude} */}
            {business.imageurl && <img src={business.imageurl} alt={business.name} className="business-image" />}
          </li>
        ))}
      </ul>
      <button onClick={navigateToAddService} className="button">Add Service <i className="fa-solid fa-arrow-right"></i></button>
    </div>
  );
}

export default AddBusiness;
