import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './ProfessionalRegister.css'; // Import the CSS file
import backgroundImage from '../MediaStores/Image2-b598cd77921dc7f9a62fb3ec31bcaa48.webp';

function Professional_register({ setUser }) {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || '';
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: email,
    contact: '',
    picture: '',
    startslot: '',
    endslot: '',
    password: '',
  });

  const validTimeSlots = [
    '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30',
    '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30',
    '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30',
    '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30',
    '22:00', '22:30', '23:00'
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if ((name === 'startslot' || name === 'endslot') && !validTimeSlots.includes(value)) {
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://backend.snyp.co.za/api/register-professional', formData);

      if (response.data.success) {
        const { token, firstname, professionalId } = response.data;
        localStorage.setItem('token', token);
        setUser({ firstname });
        navigate('/Register-add-business', { state: { email: formData.email, firstname, professionalId } });
      } else {
        console.error('Registration was not successful:', response.data.message);
        // Handle unsuccessful registration
      }
    } catch (error) {
      console.error('Error registering professional:', error);
      // Handle error from registration attempt
    }
  };

  return (
    <div className="professional-register-container">
      <div className="left-section">
        <button className="back-button" onClick={() => navigate(-1)}>&larr;</button>
        <h1 className='professional-loginh1'>Create a business account</h1>
        <p className='professional-loginp'>You're almost there! Create your new account for <strong>{email}</strong> by completing these details.</p>
        <form onSubmit={handleSubmit} className="login-form">
          <input
            name="firstname"
            value={formData.firstname}
            type="text"
            onChange={handleChange}
            placeholder="First Name"
            required
          />
          <input
            name="lastname"
            value={formData.lastname}
            type="text"
            onChange={handleChange}
            placeholder="Last Name"
            required
          />
          <input
            name="email"
            value={formData.email}
            type="email"
            onChange={handleChange}
            placeholder="Email"
            required
          />
          <input
            name="contact"
            value={formData.contact}
            type="phone"
            onChange={handleChange}
            placeholder="Phone"
              maxLength="10"
            required
          />
          <input
            name="picture"
            value={formData.picture}
            type="text"
            onChange={handleChange}
            placeholder="Picture URL"
            required
          />
          <select
            name="startslot"
            value={formData.startslot}
            onChange={handleChange}
            required
          >
            <option value="">Start Slot</option>
            {validTimeSlots.map((time) => (
              <option key={time} value={time}>{time}</option>
            ))}
          </select>
          <select
            name="endslot"
            value={formData.endslot}
            onChange={handleChange}
            required
          >
            <option value="">End Slot</option>
            {validTimeSlots.map((time) => (
              <option key={time} value={time}>{time}</option>
            ))}
          </select>
          <input
            name="password"
            value={formData.password}
            type="password"
            onChange={handleChange}
            placeholder="Password"
            required
          />
          <button type="submit" className="continue-button">Register Professional</button>
        </form>
      </div>
      <div className="right-section">
        <img src={backgroundImage} alt="Background" className="background-image" />
      </div>
    </div>
  );
}

export default Professional_register;
