import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./Service.css"; // Import the CSS file
import Modal from "./Modal"; // Import the modal component
import SkeletonLoader from "./SkeletonLoader.js"; // Import the skeleton loader component

function Service() { 
  const { professionalId } = useParams();
  const [services, setServices] = useState([]);
  const [cart, setCart] = useState([]);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [loading, setLoading] = useState(true); // State to manage loading
  const [selectedCategory, setSelectedCategory] = useState("All Categories"); // State for selected category
  const [categories, setCategories] = useState([]); // State to store categories

  const navigate = useNavigate();
  const location = useLocation();
  const {
    businessId,
    businessName,
    professionalName,
    professionalImage,
    professionalEmail,
  } = location.state || {};
  console.log("professionalEmail", professionalEmail);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(
          `https://backend.snyp.co.za/api/get-services/${professionalId}`
        );
        const updatedServices = response.data.map((service) => ({
          ...service,
          price: parseFloat(service.price),
        }));
        setServices(updatedServices);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error(
          `Failed to fetch services for professional ID: ${professionalId}:`,
          error
        );
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    if (professionalId) {
      fetchServices();
    }
  }, [professionalId]);

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`https://backend.snyp.co.za/api/get-categories/${professionalId}`);
        setCategories(response.data);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const addToCart = (service) => {
    setCart((currentCart) => {
      const isServiceInCart = currentCart.find(
        (item) => item.serviceid === service.serviceid
      );
      if (isServiceInCart) {
        return currentCart.filter(
          (item) => item.serviceid !== service.serviceid
        );
      }
      const discountedService = {
        ...service,
        discountedPrice: discountedPrice(service.price, service.discount),
      };
      return [...currentCart, discountedService];
    });
  };
  

  const totalCost = cart.reduce(
    (total, item) => total + parseFloat(item.discountedPrice || item.price),
    0
  );
  

  const goToSchedule = () => {
    navigate("/schedule", {
      state: {
        cart: cart,
        professionalId: professionalId,
        totalCost: totalCost,
        businessName: businessName,
        businessId: businessId,
        professionalName: professionalName,
        professionalImage: professionalImage,
        professionalEmail: professionalEmail,
      },
    });
  };

  // Filter services based on selected category
  const filteredServices = selectedCategory === "All Categories"
    ? services
    : services.filter(service => service.type === selectedCategory);

    const discountedPrice = (price, discount) => {
      return (price - (price * (discount / 100))).toFixed(2);
    };

 
    return (
      <div className="service-container">
        <div className="services-list">
          <h2>Choose a service</h2>
          <div className="category-dropdown">
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="All Categories">All Categories</option>
              {categories.map((category) => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div> 
          {loading ? (
            <SkeletonLoader />
          ) : (
            <div className="services-grid">
              {filteredServices.map((service) => (
                <div
                  key={service.serviceid}
                  className={`service-item ${
                    cart.find((item) => item.serviceid === service.serviceid)
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => addToCart(service)}
                >
                  <div className="service-details">
                    <h3>{service.name}</h3>
                    <p
                      className={`service-duration ${
                        cart.find((item) => item.serviceid === service.serviceid)
                          ? "selected"
                          : ""
                      }`}
                    >
                      {service.duration} mins
                    </p>
                    <p
                      className={`service-price ${
                        cart.find((item) => item.serviceid === service.serviceid)
                          ? "selected"
                          : ""
                      }`}
                    >
                      <span style={{ textDecoration: service.discount > 0 ? 'line-through' : 'none' }}>
                        R{service.price.toFixed(2)}
                      </span>
                      {service.discount > 0 && (
                        <span className="discounted-price">
                          &nbsp;R{discountedPrice(service.price, service.discount)}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
  
        {/* Desktop Cart */}
        <div className="cart-summary-desktop">
          <h3>
            Your order{" "}
            <p style={{ color: "#aaa", fontWeight: "300" }}>{businessName}</p>
          </h3>
          <div className="profile-info">
            {professionalImage && (
              <img
                src={professionalImage}
                alt={professionalName}
                className="profile-pic"
              />
            )}
            <p
              style={{
                fontWeight: "800",
                fontSize: "14px",
                position: "relative",
                left: "5px",
              }}
              className="cart-item-name"
            >
              {professionalName}{" "}
              {cart.slice(0, 1).map((item) => (
                <div
                  style={{
                    fontWeight: "800",
                    fontSize: "12px",
                    position: "relative",
                    left: "-10px",
                    top: "5px",
                  }}
                  className="cart-item"
                  key={item.serviceid}
                >
                  <p
                    className="cart-item-name"
                    style={{ position: "relative", left: "0px" }}
                  >
                    {item.name}
                  </p>
                  <p
                    className="cart-item-price"
                    style={{ position: "fixed", right: "170px" }}
                  >
                    R{item.discountedPrice || item.price}
                  </p>
                </div>
              ))}
            </p>
          </div>
          <div className="cart-details">
            {cart.slice(1, 3).map((item) => (
              <div className="cart-item" key={item.serviceid}>
                <p
                  className="cart-item-name"
                  style={{ position: "relative", left: "0px" }}
                >
                  {" "}
                  + {item.name}
                </p>
                <p
                  className="cart-item-price"
                  style={{ position: "fixed", right: "170px" }}
                >
                  R{item.discountedPrice || item.price}
                </p>
              </div>
            ))}
            {cart.length > 3 && (
              <div className="cart-item">
                <div>
                  <p className="cart-item-name">{cart.length - 3} more items</p>
                </div>
              </div>
            )}
          </div>
          <div className="order-summaryservice">
            <p
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span style={{ fontWeight: "700" }}>Subtotal:</span>
              <span className="subtotal-amount">R{totalCost.toFixed(2)}</span>
            </p>
            {cart.length > 0 && (
              <button className="choose-time-button" onClick={goToSchedule}>
                Choose a time
              </button>
            )}
          </div>
        </div>
  
        {/* Mobile Cart */}
        {cart.length > 0 && (
          <div
            className="cart-summary-mobileService"
            onClick={() => setShowModal(true)}
          >
            <p>View order</p>
            <p>R{totalCost.toFixed(2)}</p>
          </div>
        )}
  
        {/* Modal for Mobile Cart */}
        {showModal && (
          <Modal onClose={() => setShowModal(false)}>
            <div className="modal-header">
              <h3>
                Your order <p className="cart-item-business">{businessName}</p>
              </h3>
              <button
                className="close-buttonService"
                onClick={() => setShowModal(false)}
              >
                ×
              </button>
            </div>
            <div className="cart-details">
              <div className="profile-info">
                {professionalImage && (
                  <img
                    src={professionalImage}
                    alt={professionalName}
                    className="profile-pic"
                  />
                )}
                <p className="cart-item-name">{professionalName}</p>
              </div>
              <div style={{ position: "relative", bottom: "60px" }}>
                {cart.map((item) => (
                  <div
                    key={item.serviceid}
                    className="cart-service"
                    style={{ position: "relative", width: "190%" }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        width: "70%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      + {item.name}
                    </span>
                    <span style={{ position: "absolute", right: "0" }}>
                      R{item.discountedPrice || item.price}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="OntopofTime">
              <p
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span style={{ fontWeight: "700" }}>Subtotal:</span>
                <span className="subtotal-amount">R{totalCost.toFixed(2)}</span>
              </p>
            </div>
            <button className="choose-time-button" onClick={goToSchedule}>
              Choose a time
            </button>
          </Modal>
        )}
      </div>
    );
  }
  
  export default Service;
