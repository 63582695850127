import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './editservice.css';

function EditService() {
  const navigate = useNavigate();
  const location = useLocation();
  const { serviceId } = location.state || {};
  const [serviceData, setServiceData] = useState({
    name: '',
    type: '',
    category: '',
    description: '',
    duration: '',
    price: '',
    serviceAvailable: '',
    discount: '',
  });

  useEffect(() => {
    const fetchService = async () => {
      try {
        const response = await axios.get(`https://backend.snyp.co.za/api/get-service/${serviceId}`);
        setServiceData({
          name: response.data.name,
          type: response.data.type,
          category: response.data.category,
          description: response.data.description,
          duration: response.data.duration,
          price: response.data.price,
          serviceAvailable: response.data.serviceAvailable, // if you need this field
          discount: response.data.discount,
        });
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    };

    fetchService();
  }, [serviceId]);

  const handleChange = (e) => {
    setServiceData({ ...serviceData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`https://backend.snyp.co.za/api/update-service/${serviceId}`, {
        name: serviceData.name,
        type: serviceData.type,
        category: serviceData.category,
        description: serviceData.description,
        duration: serviceData.duration,
        price: serviceData.price,
        discount: serviceData.discount,
      });

      if (response.data.success) {
        alert('Service updated successfully!');
        navigate(-1); // Navigate back to the previous page
      } else {
        alert('Failed to update service. Please try again.');
      }
    } catch (error) {
      console.error('Error updating service:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const renderDurationOptions = () => {
    const options = [];
    for (let i = 30; i <= 240; i += 15) {
      options.push(<option key={i} value={i}>{i} minutes</option>);
    }
    return options;
  };

  return (
    <div className="edit-service-container">
      <h2>Edit Service</h2>
      <form onSubmit={handleSubmit} className="service-form">
        <div className="form-group">
          <label>Service name</label>
          <input
            name="name"
            value={serviceData.name}
            onChange={handleChange}
            placeholder="Service Name"
            required
          />
        </div>
        <div className="form-group">
          <label>Service type</label>
          <select
            name="type"
            value={serviceData.type}
            onChange={handleChange}
            required
          >
            <option value="">Select Service Type</option>
            <option value="Haircut">Haircut</option>
            <option value="Nail Care">Nail Care</option>
            <option value="Facial Treatment">Facial Treatment</option>
            <option value="Massage">Massage</option>
            <option value="Makeup">Makeup</option>
          </select>
        </div>
        <div className="form-group">
          <label>Service category</label>
          <select
            name="category"
            value={serviceData.category}
            onChange={handleChange}
            required
          >
            <option value="">Select Service Category</option>
            <option value="kids">Kids</option>
            <option value="men">Men</option>
            <option value="women">Women</option>
          </select>
        </div>
        <div className="form-group">
          <label>Service description</label>
          <textarea
            name="description"
            value={serviceData.description}
            onChange={handleChange}
            placeholder="Add a short description"
            maxLength="1000"
          
          />
        </div>
        <div className="form-group">
          <label>Service duration</label>
          <select
            name="duration"
            value={serviceData.duration}
            onChange={handleChange}
            required
          >
            <option value="">Select Duration</option>
            {renderDurationOptions()}
          </select>
        </div>
        {/* <div className="form-group">
          <label>Service available for</label>
          <input
            name="serviceAvailable"
            value={serviceData.serviceAvailable}
            onChange={handleChange}
            placeholder="Service Available"
            required
          />
        </div> */}
        <div className="form-group">
          <label>Price</label>
          <input
            name="price"
            value={serviceData.price}
            onChange={handleChange}
            placeholder="Price"
            required
            type="number"
          />
        </div>
        <div className="form-group">
          <label>Discount (%)</label>
          <input
            name="discount"
            value={serviceData.discount}
            onChange={handleChange}
            placeholder="Discount Percentage"
            type="number"
            min="0"
            max="100"
            required
          />
        </div>
        <button className='editservicebutton' type="submit">Update Service</button>
      </form>
    </div>
  );
}

export default EditService;
