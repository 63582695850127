import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function BookedAppointments() {
  const { id } = useParams(); // Assuming the professional ID is passed as a route parameter
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    const fetchBookedAppointments = async () => {
      try {
        const response = await axios.get(`/api/professional/${id}/booked-appointments`);
        setAppointments(response.data);
      } catch (error) {
        console.error('Error fetching booked appointments:', error);
      }
    };

    fetchBookedAppointments();
  }, [id]);

  return (
    <div>
      <h2>Booked Appointments</h2>
      {appointments.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Phone</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Business Name</th>
              <th>Date</th>
              <th>Slot</th>
              <th>Service Details</th>
              <th>Total Cost</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((appointment) => (
              <tr key={appointment.id}>
                <td>{appointment.email}</td>
                <td>{appointment.phone}</td>
                <td>{appointment.first_name}</td>
                <td>{appointment.last_name}</td>
                <td>{appointment.business_name}</td>
                <td>{appointment.date}</td>
                <td>{appointment.slot}</td>
                <td>{appointment.service_details}</td>
                <td>{appointment.total_cost}</td>
                <td>{appointment.duration} minutes</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No booked appointments.</p>
      )}
    </div>
  );
}

export default BookedAppointments;
