// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import Cash from './components/Cash';
import Yoco from './components/Yoco';
import EditService from './components/EditService';
import Card from './components/Card';
import Home from './components/Home';
import Team from './components/Team';
import Modal from './components/Modal';
import Login from './components/Login';
import Service from './components/Service';
import Success from './components/Success';
import Loader from './components/Loader.js';
import Complete from './components/Complete';
import Schedule from './components/Schedule';
import Register from './components/Register';
import Business from './components/Business';
import Dashboard from './components/Dashboard';
import CheckEmail from './components/CheckEmail';
import AddService from './components/AddService';
import Prof_Booked from './components/Prof_Booked';
import AddBusiness from './components/AddBusiness';
import Tradinghours from './components/Tradinghours';
import EditTradinghours from './components/EditTradinghours';
import Professional from './components/Professional';
import UserDashboard from './components/UserDashboard';
import EditSchedule from './components/EditSchedule.js';
import SkeletonLoader from './components/SkeletonLoader';
import TimeSlotSelector from './components/TimeSlotSelector';
import ClientOrBusiness from './components/ClientOrBusiness';
import Professional_login from './components/Professional_login';
import RegisterAddBusiness from './components/RegisterAddBusiness';
import Professional_register from './components/Professional_register';
import WhatsAppButton from './components/WhatsAppButton'; // Import the WhatsAppButton component
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [professionalId, setProfessionalId] = useState(null); // State to hold professionalId

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      setUser({ firstname: decoded.firstname, role: decoded.role });

      if (decoded.role === 'professional') {
        setProfessionalId(decoded.id); // Set the professionalId from the token
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <Router>
      <div className="home-container">
        <header className="home-header">
          <NavLink to="/" className="logo">Snyp</NavLink>
          <div className="header-buttons">
            {user ? (
              <>
                <div className="menu-dropdown">
                  <button className="header-button">{user.firstname} <i className="fa-solid fa-chevron-down"></i></button>
                  <div className="dropdown-content">
                    <ul>
                      {user.role === 'user' && (
                        <li>
                          <NavLink to="/userdashboard" activeClassName="active">Profile</NavLink>
                        </li>
                      )}
                      {user.role === 'professional' && (
                        <li>
                          <NavLink
                            to={{
                              pathname: "/dashboard",
                              state: { professionalId }
                            }}
                            activeClassName="active"
                          >
                            Business Profile
                          </NavLink>
                        </li>
                      )}
                      <li>
                        <NavLink  to="/" onClick={handleLogout}><i className="fa-solid fa-arrow-right-from-bracket"></i> Logout</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="menu-dropdown">
                  <button className="header-button">Menu <i className="fa-solid fa-chevron-down"></i></button>
                  <div className="dropdown-content">
                    <ul>
                      <li>
                        <NavLink to="/ClientOrBusiness"><i className="fa-solid fa-right-to-bracket"></i> Log in</NavLink>
                      </li>
                      <li>
                        <NavLink to="/" activeClassName="active">Home</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
      <Routes>
        {/* Client Pages */}
        <Route path="/business" element={<Business />} />
        <Route path="/yoco" element={<Yoco />} />
        <Route path="/ClientOrBusiness" element={<ClientOrBusiness />} />
        <Route path="/Modal" element={<Modal />} />
        <Route path="/professional/:businessId" element={<Professional />} />
        <Route path="/userdashboard" element={<UserDashboard />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/EditSchedule" element={<EditSchedule />} />
        <Route path="/service/:professionalId" element={<Service />} />
        <Route path="/success" element={<Success />} />
        <Route path="/success" element={<Loader />} />
        <Route path="/complete" element={<Complete />} />
        <Route path="/timeslotselector" element={<TimeSlotSelector />} />
        <Route path="/cash" element={<Cash />} />
        <Route path="/card" element={<Card />} />
        {/* Business Pages */}
        <Route path="/login" element={<Login setUser={setUser} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/edit-service/:serviceId" element={<EditService />} />

        <Route path="/professional_login" element={<Professional_login setUser={setUser} />} />
        <Route path="/professional_register" element={<Professional_register setUser={setUser} />} />
        <Route path="/checkemail" element={<CheckEmail />} />
        <Route path="/add-business" element={<AddBusiness />} />
        <Route path="/Register-add-business" element={<RegisterAddBusiness />} />
        <Route path="/trading_hours" element={<Tradinghours />} />
        <Route path="/edittrading_hours" element={<EditTradinghours />} />
        <Route path="/professional/:id/booked-appointments" element={<Prof_Booked />} />
        <Route path="/add-service" element={<AddService />} />
        <Route path="/dashboard" element={<Dashboard setUser={setUser} />} />
        <Route path="/team" element={<Team />} />
      </Routes>
      <WhatsAppButton /> {/* Add the WhatsAppButton component */}
    </Router>
  );
}

export default App;
