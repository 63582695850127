import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './Tradinghours.css';

const days = [
  { name: 'Sunday', short: 'sun' },
  { name: 'Monday', short: 'mon' },
  { name: 'Tuesday', short: 'tue' },
  { name: 'Wednesday', short: 'wed' },
  { name: 'Thursday', short: 'thu' },
  { name: 'Friday', short: 'fri' },
  { name: 'Saturday', short: 'sat' },
];

const Tradinghours = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { professionalId } = location.state || {}; // Get professionalId from state

  const [formData, setFormData] = useState({
    availability: days.reduce((acc, day) => {
      acc[day.short] = { available: true, start: '09:00', end: '17:00' };
      return acc;
    }, {}),
  });

  const handleCheckboxChange = (day) => {
    setFormData({
      ...formData,
      availability: {
        ...formData.availability,
        [day]: {
          ...formData.availability[day],
          available: !formData.availability[day].available,
        },
      },
    });
  };

  const handleTimeChange = (day, timeType, value) => {
    setFormData({
      ...formData,
      availability: {
        ...formData.availability,
        [day]: { ...formData.availability[day], [timeType]: value },
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!professionalId) {
      console.error('Professional ID is missing');
      return;
    }

    try {
      const response = await axios.post('https://backend.snyp.co.za/api/set-trading-hours', {
        professionalId,
        availability: formData.availability
      });
      console.log('Trading hours set:', response.data);

      // Navigate to dashboard with professionalId
      navigate('/dashboard', { state: { professionalId } });
    } catch (error) {
      console.error('Error setting trading hours:', error);
    }
  };

  return (
    <div className="availability-container">
      <h2>Set Trading Hours</h2>
      <form onSubmit={handleSubmit}>
        <h3>Set Availability</h3>
        {days.map((day) => (
          <div className="day-container" key={day.short}>
            <input
              type="checkbox"
              id={day.short}
              checked={formData.availability[day.short].available}
              onChange={() => handleCheckboxChange(day.short)}
            />
            <label htmlFor={day.short}>{day.name}</label>
            <input
              type="time"
              value={formData.availability[day.short].start}
              onChange={(e) =>
                handleTimeChange(day.short, 'start', e.target.value)
              }
            />
            <input
              type="time"
              value={formData.availability[day.short].end}
              onChange={(e) =>
                handleTimeChange(day.short, 'end', e.target.value)
              }
            />
          </div>
        ))}
        <button type="submit">Save Trading Hours</button>
      </form>
    </div>
  );
};

export default Tradinghours;
