import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Professional_login.css';
import backgroundImage from '../MediaStores/Image2-b598cd77921dc7f9a62fb3ec31bcaa48.webp';

function Professional_login({ setUser }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [needsPasswordCreation, setNeedsPasswordCreation] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await axios.post('https://backend.snyp.co.za/api/login-professional', { email, password, confirmPassword });
      if (response.data.success) {
        const { token, firstname } = response.data;
        localStorage.setItem('token', token);
        setUser({ firstname });
        navigate('/dashboard');
      } else if (response.data.message === 'Create Password') {
        setNeedsPasswordCreation(true);
        setShowPasswordFields(true);
      } else if (response.data.message === 'Email does not exist') {
        navigate('/professional_register', { state: { email } });
      } else if (response.data.message === 'Enter Password') {
        setShowPasswordFields(true);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError('An error occurred during login.');
      console.error('Login error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="professional-login-container">
      <div className="left-section">
        <button className="back-button" onClick={() => navigate(-1)}>&larr;</button>
        <h1 className='professional-loginh1'>Fresha for Business</h1>
        <p className='professional-loginp'>Create an account or log in to manage your Fresha business.</p>
        <form onSubmit={handleSubmit} className="login-form">
          <input
            type="email"
            name="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {showPasswordFields && (
            <>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {needsPasswordCreation && (
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm your password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              )}
            </>
          )}
          {error && <span className="error-text">{error}</span>}
          <button type="submit" className="continue-button" disabled={loading}>
            {loading ? 'Loading...' : 'Continue'}
          </button>
        </form>
        <div className="or-divider">OR</div>
        <div className="social-login-buttons">
          <button className="social-button facebook-button">
            <i className="fa-brands fa-facebook"></i> Continue with Facebook
          </button>
          <button className="social-button google-button">
            <i className="fa-brands fa-google-plus-g"></i> Continue with Google
          </button>
          <button className="social-button apple-button">
            <i className="fa-brands fa-apple"></i> Continue with Apple
          </button>
        </div>
        <div className="customer-booking">
          <p className='professional-loginp'>Business booking?</p>
          <button onClick={() => navigate('/professional_register')}>Sign up your business</button>
        </div>
        <div className="footer">
          <button className="footer-button">English</button>
          <button className="footer-button">Support</button>
          <button className="footer-button">Privacy Policy</button>
        </div>
      </div>
      <div className="right-section">
        <img src={backgroundImage} alt="Background" className="background-image" />
      </div>
    </div>
  );
}

export default Professional_login;
