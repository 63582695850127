// SkeletonLoader.js
import React from 'react';
import './SkeletonLoader.css';

const SkeletonLoader = () => {
  return (
    <div className="skeleton-container">
      <div className="skeleton-item">
        <div className="skeleton-thumbnail"></div>
        <div className="skeleton-details">
          <div className="skeleton-title"></div>
          <div className="skeleton-subtitle"></div>
          <div className="skeleton-price"></div>
        </div>
      </div>
      <div className="skeleton-item">
        <div className="skeleton-thumbnail"></div>
        <div className="skeleton-details">
          <div className="skeleton-title"></div>
          <div className="skeleton-subtitle"></div>
          <div className="skeleton-price"></div>
        </div>
      </div>
      <div className="skeleton-item">
        <div className="skeleton-thumbnail"></div>
        <div className="skeleton-details">
          <div className="skeleton-title"></div>
          <div className="skeleton-subtitle"></div>
          <div className="skeleton-price"></div>
        </div>
      </div>
      <div className="skeleton-item">
        <div className="skeleton-thumbnail"></div>
        <div className="skeleton-details">
          <div className="skeleton-title"></div>
          <div className="skeleton-subtitle"></div>
          <div className="skeleton-price"></div>
        </div>
      </div>
      {/* Add more skeleton items if needed */}
    </div>
  );
};

export default SkeletonLoader;
