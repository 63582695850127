import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './UserDashboard.css'; // Assuming you have a CSS file for styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSyncAlt, faEnvelope, faSpinner } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';

const UserDashboard = () => {
  const [appointments, setAppointments] = useState([]);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        // Fetch the user's profile
        const profileResponse = await axios.get('https://backend.snyp.co.za/api/user-profile', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const userEmail = profileResponse.data.email;
        setEmail(userEmail);

        // Fetch the user's appointments using email
        const appointmentsResponse = await axios.get(`https://backend.snyp.co.za/api/user/booked-appointments/${userEmail}`);
        setAppointments(appointmentsResponse.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setAppointments(prevAppointments => {
        const currentTime = new Date();
        return prevAppointments.map(appointment => {
          const appointmentDate = new Date(appointment.date);
          const [hours, minutes, seconds] = appointment.slot.split(':').map(Number);
          appointmentDate.setHours(hours, minutes, seconds);

          if (appointmentDate <= currentTime) {
            return { ...appointment, status: 'past' };
          }
          return appointment;
        });
      });
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  const cancelAppointment = async (appointmentId) => {
    try {
      await axios.delete(`https://backend.snyp.co.za/api/user/booked-appointments/${appointmentId}`);
      setAppointments(appointments.filter(appointment => appointment.id !== appointmentId));
    } catch (error) {
      console.error('Failed to cancel appointment:', error);
      alert('Failed to cancel appointment');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }).replace(',', '');
  };

  const upcomingAppointments = appointments.filter(appointment => {
    const appointmentDate = new Date(appointment.date);
    const [hours, minutes, seconds] = appointment.slot.split(':').map(Number);
    appointmentDate.setHours(hours, minutes, seconds);
    return appointmentDate > new Date() && appointment.status !== 'past';
  });

  const pastAppointments = appointments.filter(appointment => {
    const appointmentDate = new Date(appointment.date);
    const [hours, minutes, seconds] = appointment.slot.split(':').map(Number);
    appointmentDate.setHours(hours, minutes, seconds);
    return appointmentDate <= new Date() || appointment.status === 'past';
  });

  if (loading) return <div className="loading"><FontAwesomeIcon icon={faSpinner} spin size="3x" /></div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="dashboard-container">
      <h2><FontAwesomeIcon icon={faCalendarAlt} /> Dashboard</h2>
      <p style={{marginBottom:"15px"}}><FontAwesomeIcon icon={faEnvelope} /> {email}</p>
      <div className="appointments-section">
        <h3 style={{marginBottom:"15px"}}>Upcoming Appointments</h3>
        {upcomingAppointments.length > 0 ? (
          <div className="appointments-list">
            {upcomingAppointments.map((appointment) => (
              <div className="appointment-card" key={appointment.id}>
                <div className="appointment-image">
                  <img src={appointment.business_image_url} alt={appointment.business_name} />
                </div>
                <div className="appointment-details">
                  <h4>{appointment.business_name}</h4>
                  <p style={{color:"#989898"}}>{formatDate(appointment.date)} at {appointment.slot}</p>
                  <div className="service-details">
                    {JSON.parse(appointment.service_details).map((service, index) => (
                      <div key={index}>
                        <p style={{fontWeight:'700', fontSize:'20px'}}> {service.name}</p>
                        <p><strong>{service.duration} minutes</strong> </p>
                        <p><strong>Price:</strong> R{service.price}</p>
                        <hr style={{position:'relative', right:"70px",width:'180px'}}/>
                      </div>
                    ))}
                  </div>
                  <p>{appointment.professional_name} is cutting your hair</p>
                  <span className="status confirmed">{appointment.status}</span>
                  <button className="cancel-btn" onClick={() => cancelAppointment(appointment.id)}>Cancel</button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-appointments">
            <p>No upcoming appointments</p>
            <button className="search-salons-btn">Search salons</button>
          </div>
        )}
      </div>
      <div className="appointments-section">
        <h3>Past Appointments</h3>
        {pastAppointments.length > 0 ? (
          <div className="appointments-list">
            {pastAppointments.map((appointment) => (
              <div className="appointment-card" key={appointment.id}>
                <div className="appointment-image">
                  <img src={appointment.business_image_url} alt={appointment.business_name} />
                </div>
                <div className="appointment-details">
                  <h4>{appointment.business_name}</h4>
                  <p>{formatDate(appointment.date)} at {appointment.slot}</p>
                  <div className="service-details">
                    {JSON.parse(appointment.service_details).map((service, index) => (
                      <div key={index}>
                        <p style={{fontWeight:'700'}}> {service.name}</p>
                        <p><strong>Duration:</strong> {service.duration} mins</p>
                        <p><strong>Price:</strong> R{service.price}</p>
                      </div>
                    ))}
                  </div>
                  <p>{appointment.professional_name} was cutting your hair</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No past appointments</p>
        )}
      </div>
    </div>
  );
};

export default UserDashboard;
