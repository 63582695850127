import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Register.css'; // Import the CSS file

function Register() {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [password, setPassword] = useState('');
  const [profilepic, setProfilepic] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://backend.snyp.co.za/api/register-user', {
        firstname,
        lastname,
        email,
        contact,
        whatsapp,
        password,
        profilepic
      });

      if (response.data.success) {
        localStorage.setItem('token', response.data.token);
        navigate('/userdashboard');
      } else {
        alert('Registration failed');
      }
    } catch (error) {
      console.error('Registration error:', error);
      alert('An error occurred during registration');
    }
  };

  return (
    <div className="register-container">
      <div className="form-container">
        <h2>Create account</h2>
        <p className="subtitle">You're almost there! Create your new account by completing these details</p>
        <form onSubmit={handleRegister}>
          <input
            type="text"
            placeholder="First name *"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            required
            className="input-field"
          />
          <input
            type="text"
            placeholder="Last name *"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
            required
            className="input-field"
          />
          <input
            type="email"
            placeholder="Email *"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="input-field"
          />
          <input
            type="password"
            placeholder="Password *"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="input-field"
          />
          <input
            type="text"
            placeholder="Mobile number *"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            required
              maxLength="10"
            className="input-field"
          />
          <div className="checkbox-container">
            <input type="checkbox" id="privacy" required />
            <label htmlFor="privacy">I agree to the <a href="#">Privacy Policy</a>, <a href="#">Terms of Use</a> and <a href="#">Terms of Service</a></label>
          </div>
          <div className="checkbox-container">
            <input type="checkbox" id="marketing" />
            <label htmlFor="marketing">I agree to receive marketing notifications with offers and news</label>
          </div>
          <button type="submit" className="submit-button">Continue</button>
        </form>
        <div className="footer-links">
          <a href="#">English</a>
          <a href="#">Support</a>
        </div>
      </div>
      <div className="image-container">
        {/* Add any image or background you want here */}
      </div>
    </div>
  );
}

export default Register;
