// src/components/WhatsAppButton.js

import React from 'react';
import './WhatsAppButton.css';

const WhatsAppButton = () => {
  const whatsappNumber = "+27679105733"; // Replace with your actual WhatsApp number
  const message = "Hello! I need some assistance."; // Default message

  return (
    <a
      href={`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`}
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i class="fa-brands fa-whatsapp"></i>
    </a>
  );
};

export default WhatsAppButton;
