import React, { useState } from 'react';
import './styles.css';

const generateTimeSlots = (start, end, interval) => {
  let timeSlots = [];
  let currentTime = new Date();
  currentTime.setHours(start, 0, 0, 0);
  const endTime = new Date();
  endTime.setHours(end, 0, 0, 0);

  while (currentTime < endTime) {
    timeSlots.push(new Date(currentTime));
    currentTime.setMinutes(currentTime.getMinutes() + interval);
  }

  return timeSlots;
};

const TimeSlotSelector = () => {
  const initialSelectedSlots = [1, 4]; // Hardcoded selected slots
  const [selectedSlots, setSelectedSlots] = useState(initialSelectedSlots);
  const timeSlots = generateTimeSlots(7, 23, 60);

  const handleSlotClick = (index, duration) => {
    let newSelectedSlots = [...selectedSlots];
    const slotsToSelect = duration > 60 ? 2 : 1;

    for (let i = index; i < index + slotsToSelect && i < timeSlots.length; i++) {
      if (!newSelectedSlots.includes(i)) {
        newSelectedSlots.push(i);
      } else {
        newSelectedSlots = newSelectedSlots.filter(slotIndex => slotIndex !== i);
      }
    }

    setSelectedSlots(newSelectedSlots);
  };

  return (
    <div>
      {timeSlots.map((time, index) => (
        <div
          key={index}
          className={`time-slot ${selectedSlots.includes(index) ? 'selected' : ''}`}
          onClick={() => handleSlotClick(index, 65)} // Assuming a duration of 45 minutes
        >
          {time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </div>
      ))}
    </div>
  );
};

export default TimeSlotSelector;
