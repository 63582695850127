import React from 'react';
import './Loader.css';

const Loader = () => {
  return (
    <div className="item loader">
      <div className="image-placeholder"></div>
      <div className="info-placeholder">
        <div className="line-placeholder short"></div>
        <div className="line-placeholder medium"></div>
        <div className="line-placeholder long"></div>
      </div>
    </div>
  );
}

export default Loader;
