//we shoudld add a textbox to replace hardcoded duration
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import './EditSchedule.css';

function EditSchedule() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { professionalId, professionalName, professionalImage, businessId } = state || {};

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slots, setSlots] = useState([]);
  const [disabledSlots, setDisabledSlots] = useState([]);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [professionalDetails, setProfessionalDetails] = useState({
    name: '',
    picture: '',
  });
  const [currentMonth, setCurrentMonth] = useState(moment().month());
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchProfessionalDetails = async () => {
      if (!professionalId) return;
      try {
        const { data } = await axios.get(`https://backend.snyp.co.za/api/professionals/${professionalId}`);
        setProfessionalDetails({ name: data.firstname, picture: data.picture });
      } catch (error) {
        console.error(`Failed to fetch details for professional ID: ${professionalId}:`, error);
      }
    };
    fetchProfessionalDetails();
  }, [professionalId]);

  useEffect(() => {
    const fetchProfessionalSchedule = async () => {
      if (professionalId) {
        try {
          const response = await axios.get(`https://backend.snyp.co.za/api/professional/${professionalId}`);
          const { startslot, endslot } = response.data;
          setSlots(generateSlots(startslot, endslot));
        } catch (error) {
          console.error(`Failed to fetch professional schedule for ID: ${professionalId}:`, error);
        }
      }
    };
    fetchProfessionalSchedule();
    fetchDisabledSlots();
    fetchBookedSlots();
  }, [professionalId]);

  useEffect(() => {
    if (slots.length > 0) {
      setSelectedDay(slots[0]); // Set the first date as the default selected day
    }
  }, [slots]);

  const fetchDisabledSlots = async () => {
    if (!professionalId) return;
    try {
      const { data } = await axios.get(`https://backend.snyp.co.za/api/disabled-slots/${professionalId}`);
      setDisabledSlots(data);
    } catch (error) {
      console.error(`Failed to fetch disabled slots for professional ID: ${professionalId}:`, error);
    }
  };

  const fetchBookedSlots = async () => {
    if (!professionalId) return;
    try {
      const { data } = await axios.get(`https://backend.snyp.co.za/api/booked-slots/${professionalId}`);
      setBookedSlots(data);
    } catch (error) {
      console.error(`Failed to fetch booked slots for professional ID: ${professionalId}:`, error);
    }
  };

  const handleMonthChange = (direction) => {
    const newDate = moment()
      .year(currentYear)
      .month(currentMonth)
      .add(direction, 'months');
    setCurrentMonth(newDate.month());
    setCurrentYear(newDate.year());
  };

  const filteredSlots = slots.filter(
    (day) =>
      moment(day.date).year() === currentYear &&
      moment(day.date).month() === currentMonth
  );

  const generateSlots = (start, end) => {
    let days = [];
    for (let i = 0; i < 7; i++) {
      let day = moment().add(i, 'days');
      days.push({
        date: day.format('YYYY-MM-DD'),
        slots: generateDaySlots(day, start, end),
      });
    }
    return days;
  };

  const generateDaySlots = (day, start, end) => {
    let slots = [];
    let startTime = moment(day.format('YYYY-MM-DD') + ' ' + start);
    let endTime = moment(day.format('YYYY-MM-DD') + ' ' + end);
  
    while (startTime <= endTime) {
      const isDisabled = disabledSlots.some(
        (slot) => slot.date === day.format('YYYY-MM-DD') && slot.slot === startTime.format('HH:mm')
      );
      slots.push({
        time: startTime.format('HH:mm'),
        enabled: !isDisabled,
      });
      startTime.add(30, 'minutes'); // Change to 30 minutes
    }
    return slots;
  };
  

  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  const handleSlotSelection = async (slot) => {
    if (!selectedDay) return;

    const isAlreadyBooked = bookedSlots.some(
      (bookedSlot) => bookedSlot.date === selectedDay.date && bookedSlot.slot === slot
    );

    try {
      if (isAlreadyBooked) {
        // Cancel the appointment
        await axios.delete(`https://backend.snyp.co.za/api/book-appointment`, {
          data: {
            professionalId,
            selectedDate: selectedDay.date,
            selectedTime: slot,
          },
        });
        setBookedSlots((prev) =>
          prev.filter(
            (bookedSlot) =>
              bookedSlot.date !== selectedDay.date || bookedSlot.slot !== slot
          )
        );
      } else {
        // Book the appointment
        const appointmentDetails = {
          email: 'gcinumusaduma@gmail.com',
          phone: '1234567890',
          firstName: 'John',
          lastName: 'Doe',
          businessName: 'Business Name',
          selectedDate: selectedDay.date,
          selectedTime: slot,
          professionalName: professionalDetails.name,
          professionalEmail: 'gcinumusaduma@gmail.com',
          cart: [],
          totalCost: 0,
          professionalId,
          duration: 30,
          businessId, // Use the businessId received from state
          paymentMethod: 'Time Off',
        };

        await axios.post(`https://backend.snyp.co.za/api/book-appointment`, appointmentDetails);
        setBookedSlots((prev) => [...prev, { date: selectedDay.date, slot }]);
      }

      // Refetch the schedule and slots
      fetchBookedSlots();
      fetchDisabledSlots();
    } catch (error) {
      console.error(isAlreadyBooked ? 'Failed to cancel appointment:' : 'Failed to book appointment:', error);
    }

    setSelectedSlot(slot);
  };

  const handleDeleteSlot = async (day, slot) => {
    try {
      await axios.delete(`https://backend.snyp.co.za/api/delete-booked-appointment`, {
        data: {
          professionalId,
          selectedDate: day,
          selectedTime: slot,
          email: 'gcinumusaduma@gmail.com',
        },
      });
      setBookedSlots((prev) =>
        prev.filter(
          (bookedSlot) =>
            !(bookedSlot.date === day && bookedSlot.slot === slot && bookedSlot.email === 'gcinumusaduma@gmail.com')
        )
      );
      fetchBookedSlots();
      fetchDisabledSlots();
    } catch (error) {
      console.error('Failed to delete booked appointment:', error);
    }
  };

  const bookedslots = bookedSlots.map((slot) => ({
    date: moment(slot.date).format('YYYY-MM-DD'),
    slot: moment(slot.slot, 'HH:mm:ss').format('HH:mm'),
    email: slot.email,
  }));

  const isDummySlot = (day, slot) => {
    return bookedslots.some((d) => d.date === day && d.slot === slot);
  };

  const isDummyDay = (day) => {
    return bookedslots.some((d) => d.date === day);
  };
//we shoudld add a textbox to replace hardcoded duration
  return (
    <div>
      <button onClick={() => navigate(-1)} className="back-button">Back</button>
      <div className="dateandslot">
        <div className="time-selection-container">
          <h2 className="time-selection-heading">
            <span>Choose a time</span>
          </h2>
          <span>{moment().year(currentYear).month(currentMonth).format('MMMM YYYY')}</span>
        </div>

        <div>
          {slots.map((day, index) => {
            return (
              <div key={index} className="slot-container">
                <button
                  onClick={() => handleDayClick(day)}
                  className={`slot-button ${selectedDay?.date === day.date ? 'selected' : ''}`}
                >
                  {moment(day.date).format('DD')}
                </button>
                <div className={`slot-day ${selectedDay?.date === day.date ? 'selected' : ''}`}>
                  {moment(day.date).format('ddd')}
                </div>
              </div>
            );
          })}
        </div>

        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', marginBottom: '20px' }}>
          {professionalDetails.picture && (
            <img
              src={professionalDetails.picture}
              alt={professionalName}
              className="profile-pic"
              style={{ marginRight: '20px' }}
            />
          )}
          {professionalDetails.name && (
            <h3 style={{ marginTop: '-35px' }}>{professionalDetails.name}</h3>
          )}
        </div>

        {selectedDay && (
          <div>
            <h3>Available Slots for {moment(selectedDay.date).format('Do MMMM YYYY')}</h3>
            <div className="slots-container">
              {selectedDay.slots
                .map((slot, index) => {
                  const isBooked = isDummySlot(selectedDay.date, slot.time);
                  const bookedSlot = bookedslots.find((d) => d.date === selectedDay.date && d.slot === slot.time);
                  const buttonColor = bookedSlot?.email === 'gcinumusaduma@gmail.com' ? 'blue' : 'red';
                  return (
                    <React.Fragment key={index}>
                      {index % (isMobile ? 3 : 4) === 0 && index !== 0 && <br />}
                      <button
                        onClick={() => {
                          if (buttonColor === 'blue') {
                            handleDeleteSlot(selectedDay.date, slot.time);
                          } else {
                            handleSlotSelection(slot.time);
                          }
                        }}
                        className={`slot-prof ${selectedSlot === slot.time ? 'selected' : ''} ${isBooked ? 'booked' : ''}`}
                        style={isBooked ? { backgroundColor: buttonColor, color:'white' } : {}}
                        disabled={!slot.enabled}
                      >
                        <div>
                          <i className="fas fa-sun"></i> {slot.time}
                        </div>
                      </button>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        )}
      </div>
      {/* <button
        onClick={() => navigate('/edittrading_hours', { state: { professionalId } })}
        className="edit-trading-hours-button"
      >
        Edit Trading Hours
      </button> */}
    </div>
  );
}

export default EditSchedule;
