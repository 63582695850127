import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Home.css';
import Loader from './Loader.js';

function Home() {
  const navigate = useNavigate();
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchName, setSearchName] = useState('');
  const [searchAddress, setSearchAddress] = useState('');

  useEffect(() => {
    fetchBusinessData();
  }, []);

  const fetchBusinessData = async (name = '', address = '') => {
    try {
      setLoading(true);
      const response = await axios.get('https://backend.snyp.co.za/api/get-businesses', {
        params: { name, address }
      });
      // Filter out businesses with id 7
      const filteredBusinesses = response.data.filter(business => business.businessid !== 7);
      setBusinesses(filteredBusinesses);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch business data:', error);
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchBusinessData(searchName, searchAddress);
  };

  const handleBusinessClick = (businessId) => {
    navigate(`/professional/${businessId}`);
  };

  const categorizeBusinesses = (businesses) => {
    const categories = {};
    businesses.forEach((business) => {
      if (!categories[business.category]) {
        categories[business.category] = [];
      }
      categories[business.category].push(business);
    });
    return categories;
  };

  const businessCategories = categorizeBusinesses(businesses);

  return (
    <main className="home-main">
      <h1 className="main-heading">Save time, use Snyp to book your haircut</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Any treatment or venue"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Current location"
          value={searchAddress}
          onChange={(e) => setSearchAddress(e.target.value)}
        />
        <input type="text" placeholder="Any date" />
        <input type="text" placeholder="Any time" />
        <button className="search-button" onClick={handleSearch}>Search</button>
      </div>
      <p className="appointments-booked">308,595 appointments booked today</p>
      <button className="get-app-button">Get the app</button>

      {loading ? (
        [1, 2, 3, 4].map((_, index) => <Loader key={index} />)
      ) : (
        Object.keys(businessCategories).map((category) => (
          <div key={category} className="business-category">
            <h2>{category}</h2>
            <div className="business-items">
              {businessCategories[category].map((business) => (
                <div key={business.businessid} className="item" onClick={() => handleBusinessClick(business.businessid)}>
                  <img src={business.imageurl} alt={business.name} />
                  <div className="item-info">
                    <h3>{business.name}</h3>
                    <div className="rating">
                      <span style={{ color: "#000", fontWeight: '700' }}>{business.rating} ★</span>
                      <span>({business.review_count})</span>
                    </div>
                    <p>{business.address}</p>
                    <button className="tag">{business.category}</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      )}
        <div className="business-category">
            <h2>Coming Soon</h2>
            <div className="business-items">
              <> 
                <div className="item">
                  <img src="https://thezoneatrosebank.co.za/the_zone/uploads/l1.jpg" alt="" />
                  <div className="item-info">
                    <h3>Legendary Barber - Umhlanga</h3>
                    <div className="rating">
                      <span style={{ color: "#000", fontWeight: '700' }}>5.6 ★</span>
                      <span>45k</span>
                    </div>
                    <p>Coming soon</p>
                    <button className="tag">Barbershop</button>
                  </div>
                </div>
                <div className="item">
                  <img src="https://libertypromenade.co.za/uploads/legens1.jpg" alt="" />
                  <div className="item-info">
                    <h3>Legendary Barber - Florida</h3>
                    <div className="rating">
                      <span style={{ color: "#000", fontWeight: '700' }}>5.6 ★</span>
                      <span>45k</span>
                    </div>
                    <p>Coming soon</p>
                    <button className="tag">Barbershop</button>
                  </div>
                </div>
                </>
            </div>
        </div>
    </main>
  );
}

export default Home;
