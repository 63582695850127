import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './AddBusiness.css';

function RegisterAddBusiness() {
  const navigate = useNavigate();
  const location = useLocation();
  const { professionalId } = location.state || {};
  console.log("my prof id:", professionalId);

  const [businessData, setBusinessData] = useState({
    name: '',
    address: '',
    category: '',
    latitude: '',
    longitude: ''
  });

  const [businesses, setBusinesses] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const response = await axios.get(`https://backend.snyp.co.za/api/businesses-by-professional/${professionalId}`);
        setBusinesses(response.data);
      } catch (error) {
        console.error('Error fetching businesses:', error);
      }
    };

    fetchBusinesses();
  }, [professionalId]);

  const handleAddressChange = (e) => {
    const inputValue = e.target.value;
    setBusinessData({ ...businessData, address: inputValue });

    if (inputValue.length > 2) {
      axios.get(`https://api.geoapify.com/v1/geocode/autocomplete?text=${inputValue}&apiKey=c86269d939f84d3980a3c26589aede68`)
        .then(response => {
          setSuggestions(response.data.features);
        })
        .catch(error => {
          console.error('Error fetching suggestions:', error);
        });
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setBusinessData({
      ...businessData,
      address: suggestion.properties.formatted,
      latitude: suggestion.geometry.coordinates[1], // Latitude
      longitude: suggestion.geometry.coordinates[0] // Longitude
    });
    setSuggestions([]);
  };

  const handleChange = (e) => {
    setBusinessData({ ...businessData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');

    const duplicate = businesses.find(business =>
      business.name === businessData.name &&
      business.address === businessData.address &&
      business.category === businessData.category
    );

    if (duplicate) {
      alert('Business already exists');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.post('https://backend.snyp.co.za/api/add-business', {
        ...businessData,
        professionalId,
      });

      if (response.data.success) {
        console.log('Business added successfully');
        const businessId = response.data.businessId;
        console.log("businessid just added coming back from table: ", businessId);

        const associateResponse = await axios.post('https://backend.snyp.co.za/api/associate-business-professional', {
          businessId,
          professionalId,
        });

        if (associateResponse.data.success) {
          console.log('Association created successfully');
        } else {
          console.error('Failed to create association');
        }

        navigate('/dashboard', { state: { professionalId } });
      } else {
        console.error('Failed to add business');
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error adding business:', error);
      if (error.response && error.response.status === 409) {
        setErrorMessage('Business already exists');
      } else {
        setErrorMessage('Internal server error');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container">
      <h2 className="title">What is your business name?</h2>
      <p className='p-register'>This is the name your clients will see</p>
      <form className="form" onSubmit={handleSubmit}>
        <input
          name="name"
          value={businessData.name}
          onChange={handleChange}
          placeholder="Business Name"
          required
          className="input"
        />
        <input
          name="address"
          value={businessData.address}
          onChange={handleAddressChange}
          placeholder="Business Address"
          required
          className="input"
        />
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="suggestion-item"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.properties.formatted}
            </li>
          ))}
        </ul>
        <select
          name="category"
          value={businessData.category}
          onChange={handleChange}
          required
          className="select"
        >
          <option value="">Select a Category</option>
          <option value="Hair Salon">Hair Salon</option>
          <option value="Nail Salon">Nail Salon</option>
          <option value="Spa">Spa</option>
          <option value="Barbershop">Barbershop</option>
        </select>
        <button type="submit" className="button" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}

export default RegisterAddBusiness;
