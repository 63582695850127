import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Professional.css'; // Import the CSS file for styling

function Professional() {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const [professionals, setProfessionals] = useState([]);
  const [businessName, setBusinessName] = useState(''); // State for storing the business name
  const [loading, setLoading] = useState(true); // State for loading

  useEffect(() => {
    // Fetch professionals
    const fetchProfessionals = async () => {
      try {
        const response = await axios.get(`https://backend.snyp.co.za/api/get-professionals/${businessId}`);
        setProfessionals(response.data);
      } catch (error) {
        console.error('Failed to fetch professionals:', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    // Fetch business details including the name
    const fetchBusinessName = async () => {
      try {
        const response = await axios.get(`https://backend.snyp.co.za/api/businesses/${businessId}`);
        setBusinessName(response.data.name);
      } catch (error) {
        console.error('Failed to fetch business details:', error);
      }
    };

    if (businessId) {
      fetchProfessionals();
      fetchBusinessName();
    }
  }, [businessId]);

  const handleProfessionalClick = (professional) => {
    navigate(`/service/${professional.professionalid}`, {
      state: {
        businessId,
        businessName,
        professionalName: `${professional.firstname} ${professional.lastname}`,
        professionalImage: professional.picture,
        professionalEmail: professional.email // Pass the email to the Service component
      }
    });
  };

  const getInitials = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + '.';
  };

  return (
    <div className="professional-container">
      <h1 className='Chooseaprofessionalh1'>{businessName ? `Choose a professional` : 'Professionals'}</h1>
      <div className="professional-content">
        <div className="professional-list">
          {loading ? (
            // Skeleton loader
            Array(4).fill().map((_, index) => (
              <div key={index} className="skeleton-card">
                <div className="skeleton-image"></div>
                <div className="skeleton-text"></div>
                <div className="skeleton-text"></div>
              </div>
            ))
          ) : (
            professionals.length > 0 ? (
              professionals.map((professional) => (
                <div key={professional.professionalid} className="professional-card" onClick={() => handleProfessionalClick(professional)}>
                  <div className="professional-image">
                    {professional.picture ? (
                      <img src={professional.picture} alt={`${professional.firstname} ${professional.lastname}`} />
                    ) : (
                      <div className="placeholder-image">No Image</div>
                    )}
                  </div>
                  <div className="professional-info">
                    <h3>{professional.firstname} {getInitials(professional.lastname)}</h3>
                    <hr/>
                    <p>Available Today</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No professionals found for this business.</p>
            )
          )}
        </div>
        <div className="professional-summary">
          <h2 className='Chooseaprofessionalh2'>{businessName}</h2>
          <div className="summary-placeholder">
            {/* Placeholder content for the summary section */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Professional;
