import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import './Schedule.css';
import Modal from './Modal';
import sa from '../MediaStores/south-africa.png';

function Schedule() {
  const { state } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const { businessId, businessName, professionalName, professionalImage, professionalEmail } =
    location.state || {};

  const [showModal, setShowModal] = useState(false);
  const [cart, setCart] = useState([]);
  const [professionalId, setProfessionalId] = useState('');
  const [totalCost, setTotalCost] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slots, setSlots] = useState([]);
  const [disabledSlots, setDisabledSlots] = useState([]);
  const [showContinue, setShowContinue] = useState(false);
  const [showContinueOptions, setShowContinueOptions] = useState(false);
  const [showCashForm, setShowCashForm] = useState(false);
  const [professionalDetails, setProfessionalDetails] = useState({
    name: '',
    picture: '',
  });

  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [currentMonth, setCurrentMonth] = useState(moment().month());
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [bookedSlots, setBookedSlots] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [dayAvailability, setDayAvailability] = useState({
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
    Sunday: true,
  });


  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchDisabledSlots = async () => {
    if (!professionalId) return;
    try {
      const { data } = await axios.get(`https://backend.snyp.co.za/api/disabled-slots/${professionalId}`);
      setDisabledSlots(data);
    } catch (error) {
      console.error(`Failed to fetch disabled slots for professional ID: ${professionalId}:`, error);
    }
  };

  const fetchBookedSlots = async () => {
    if (!professionalId) return;
    try {
      const { data } = await axios.get(`https://backend.snyp.co.za/api/booked-slots/${professionalId}`);
      const bookedSlotsWithRange = data.map(slot => {
        const startTime = moment(slot.slot, 'HH:mm:ss');
        const endTime = startTime.clone().add(slot.duration, 'minutes');
        let range = [];
        let currentTime = startTime.clone();
        while (currentTime < endTime) {
          range.push(currentTime.format('HH:mm'));
          currentTime.add(15, 'minutes');
        }
        return {
          date: moment(slot.date).format('YYYY-MM-DD'),
          range,
        };
      });
      setBookedSlots(bookedSlotsWithRange);
    } catch (error) {
      console.error(`Failed to fetch booked slots for professional ID: ${professionalId}:`, error);
    }
  };
  

  const handleMonthChange = (direction) => {
    const newDate = moment()
      .year(currentYear)
      .month(currentMonth)
      .add(direction, 'months');
    setCurrentMonth(newDate.month());
    setCurrentYear(newDate.year());
  };

  const filteredSlots = slots.filter(
    (day) =>
      moment(day.date).year() === currentYear &&
      moment(day.date).month() === currentMonth
  );

  useEffect(() => {
    if (state) {
      setCart(state.cart);
      setProfessionalId(state.professionalId);
      setTotalCost(state.totalCost);
    }
  }, [state]);

  useEffect(() => {
    const fetchProfessionalDetails = async () => {
      if (!professionalId) return;
      try {
        const { data } = await axios.get(`https://backend.snyp.co.za/api/professionals/${professionalId}`);
        setProfessionalDetails({ name: data.firstname, picture: data.picture });
      } catch (error) {
        console.error(`Failed to fetch details for professional ID: ${professionalId}:`, error);
      }
    };

    fetchProfessionalDetails();
  }, [professionalId]);

  useEffect(() => {
    const fetchProfessionalSchedule = async () => {
      if (professionalId) {
        try {
          const response = await axios.get(`https://backend.snyp.co.za/api/professional/${professionalId}`);
          const { startslot, endslot } = response.data;
          setSlots(generateSlots(startslot, endslot));
        } catch (error) {
          console.error(`Failed to fetch professional schedule for ID: ${professionalId}:`, error);
        }
      }
    };
    fetchProfessionalSchedule();
    fetchDisabledSlots();
    fetchBookedSlots();
  }, [professionalId]);

  const generateSlots = (start, end) => {
    let days = [];
    for (let i = 0; i < 7; i++) {
      let day = moment().add(i, 'days');
      days.push({
        date: day.format('YYYY-MM-DD'),
        slots: generateDaySlots(day, start, end),
      });
    }
    return days;
  };
  

  const generateDaySlots = (day, start, end) => {
    let slots = [];
    let startTime = moment(day.format('YYYY-MM-DD') + ' ' + start);
    let endTime = moment(day.format('YYYY-MM-DD') + ' ' + end);
  
    while (startTime <= endTime) {
      const isDisabled = disabledSlots.some(
        (slot) => slot.date === day.format('YYYY-MM-DD') && slot.slot === startTime.format('HH:mm')
      ) || isDummySlot(day.format('YYYY-MM-DD'), startTime.format('HH:mm'));
      slots.push({
        time: startTime.format('HH:mm'),
        enabled: !isDisabled,
      });
      startTime.add(15, 'minutes'); // Change interval to 15 minutes
    }
    return slots;
  };
  
  useEffect(() => {
    if (slots.length > 0) {
      setSelectedDay(slots[0]); // Set the first date as the default selected day
      const firstAvailableSlot = slots[0].slots.find(slot => slot.enabled);
      if (firstAvailableSlot) {
        setSelectedSlot(firstAvailableSlot.time); // Set the first available slot
      }
    }
  }, [slots]);
  

  const handleDayClick = (day) => {
    setSelectedDay(day);
  
    const firstAvailableSlot = day.slots.find(slot => slot.enabled);
    if (firstAvailableSlot) {
      setSelectedSlot(firstAvailableSlot.time);
    }
  };
  

  const handleSlotSelection = (slot) => {
    if (!selectedDay) return;
  
    setSelectedSlot(slot);
    const appointmentDuration = cart.reduce((total, item) => total + item.duration, 0); // Assuming duration is in minutes
    const selectedTime = moment(selectedDay.date + ' ' + slot, 'YYYY-MM-DD HH:mm');
  
    const updatedSlots = selectedDay.slots.map(s => {
      const slotTime = moment(selectedDay.date + ' ' + s.time, 'YYYY-MM-DD HH:mm');
      const isWithinDuration = slotTime.isBetween(selectedTime, selectedTime.clone().add(appointmentDuration, 'minutes'), null, '[]');
      return {
        ...s,
        enabled: !isWithinDuration ? s.enabled : false, // Disable slots within the duration
      };
    });
  
    setSelectedDay({
      ...selectedDay,
      slots: updatedSlots,
    });
  
    const updatedCart = cart.map((item) => ({
      ...item,
      appointmentDate: selectedDay.date,
      appointmentTime: slot,
    }));
    setCart(updatedCart);
    setShowContinue(true);
  };
  
  

  const handleFirstOption = () => {
    console.log('First option selected');
  };

  const handleShowContinueOptions = () => {
    setShowCashForm(false);
    setShowContinueOptions(true);
  };
  const handleCashPayment = () => {
    setShowCashForm(true);
    setShowContinueOptions(false);
  };

  const bookedslots = bookedSlots.map((slot) => ({
    date: moment(slot.date).format('YYYY-MM-DD'),
    slot: moment(slot.slot, 'HH:mm:ss').format('HH:mm'),
  }));

  const isDummySlot = (day, slot) => {
    return bookedSlots.some((booked) => booked.date === day && booked.range.includes(slot));
  };
  

  const isDummyDay = (day) => {
    return bookedslots.some((d) => d.date === day);
  };

  useEffect(() => {
    if (state) {
      setCart(state.cart);
      setProfessionalId(state.professionalId);
      setTotalCost(state.totalCost);
    }
  }, [state]);

  useEffect(() => {
    async function fetchSchedule() {
      if (professionalId) {
        try {
          const { data } = await axios.get(`https://backend.snyp.co.za/api/professional/${professionalId}`);
          const { startslot, endslot } = data;
          setSlots(generateSlots(startslot, endslot));
        } catch (error) {
          console.error(`Failed to fetch professional schedule:`, error);
        }
      }
    }
    fetchSchedule();
    fetchBookedSlots();
  }, [professionalId]);

  useEffect(() => {
    const duration = cart.reduce((total, item) => total + item.duration, 0);
    setTotalDuration(duration);
  }, [cart]);

  const handleCashFormSubmit = (e) => {
    e.preventDefault();
    navigate('/success', {
      state: {
        email,
        phoneNumber,
        firstName,
        lastName,
        businessId,
        businessName,
        professionalEmail,
        selectedDay: selectedDay.date,
        selectedTime: selectedSlot,
        professionalName: professionalDetails.name,
        totalCost: totalCost.toFixed(2),
        professionalId,
        totalDuration,
        cart,
        paymentType: 'cash',
      },
    });
  };
  const discountedPrice = (price, discount) => {
    return (price - (price * (discount / 100))).toFixed(2);
  };
  
  const addToCart = (service) => {
    setCart((currentCart) => {
      const isServiceInCart = currentCart.find(
        (item) => item.serviceid === service.serviceid
      );
      if (isServiceInCart) {
        return currentCart.filter(
          (item) => item.serviceid !== service.serviceid
        );
      }
      const discountedService = {
        ...service,
        discountedPrice: discountedPrice(service.price, service.discount),
      };
      return [...currentCart, discountedService];
    });
  };
  
  return (
    <div>
      <div className="dateandslot">
        <div className="time-selection-container">
          <h2 className="time-selection-heading">
            <span>Choose a time</span>
          </h2>
          <span>{moment().year(currentYear).month(currentMonth).format('MMMM YYYY')}</span>
        </div>

        <div>
          {slots.map((day, index) => {
            const dayName = moment(day.date).format('dddd');
            const isDisabled = !dayAvailability[dayName];

            return (
              <div key={index} className="slot-container">
                <button
                  onClick={() => !isDisabled && handleDayClick(day)}
                  className={`slot-button ${selectedDay?.date === day.date ? 'selected' : ''}`}
                  disabled={isDisabled}
                  style={{
                    backgroundColor: isDisabled ? '#d3d3d3' : '',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                  }}
                >
                  {moment(day.date).format('DD')}
                </button>
                <div className={`slot-day ${selectedDay?.date === day.date ? 'selected' : ''}`}>
                  {moment(day.date).format('ddd')}
                </div>
              </div>
            );
          })}
        </div>

        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', marginBottom: '20px' }}>
          {professionalDetails.picture && (
            <img
              src={professionalDetails.picture}
              alt={professionalName}
              className="profile-pic"
              style={{ marginRight: '20px' }}
            />
          )}

          {professionalDetails.name && (
            <h3 style={{ marginTop: '-35px' }}>{professionalDetails.name}</h3>
          )}
            {cart.slice(0, 1).map((item) => ( 
<>
  <p className="servicefirst" style={{fontSize:'10px'}}>{item.name}</p>

</>
               ))}  
        </div>

        {selectedDay && (
          <div>
            <h3>Available Slots for {moment(selectedDay.date).format('Do MMMM YYYY')}</h3>
            <div className="slots-container">
              {selectedDay.slots
                .filter((slot) => !isDummySlot(selectedDay.date, slot.time))
                .map((slot, index) => (
                  <React.Fragment key={index}>
                    {index % (isMobile ? 3 : 6) === 0 && index !== 0 && <br />}
                    <button
                      onClick={() => handleSlotSelection(slot.time)}
                      className={`${selectedSlot === slot.time ? 'selected' : ''}`}

                    >
                      <div>
                        <i className="fas fa-sun"></i> {slot.time}
                      </div>
                    </button>
                  </React.Fragment>
                ))}
            </div>
          </div>
        )}
      </div>
      
      <div className="cart-summary-desktop">
      <div className="order-summarynew">
      <h2>Your order</h2>
      <p className="location">{businessName}</p>
      <div className="sitem">
        <div className="sitem-details">
         {professionalImage && (
            <img src={professionalImage} alt={professionalName} className="profile-pic" />
          )}  
            <div className="sitem-info" >
                <p className="service"> {professionalName}{' '}</p>
                 {cart.slice(0, 1).map((item) => ( 
<>
  <p className="service" style={{fontSize:'10px'}}>{item.name}</p>
                <div className="sitem-price" style={{fontSize:'10px'}}> R{item.discountedPrice}</div>

</>
               ))}  
            </div> 
        </div>
        </div>  
         {cart.slice(1, 5).map((item) => (
        <div className="additional-services" key={item.serviceid}>
                <p style={{fontSize:'10px'}}>+ {item.name} 
                  <span className="price">R{item.discountedPrice}</span>
                </p>
        </div>    
      ))}
            <div className="additional-services">
                <p  style={{fontSize:'10px'}}>{selectedDay ? `${moment(selectedDay.date).format('MMM Do')} at ${moment(selectedSlot, 'HH:mm').format('h:mmA')}`: 'Select your Date'} 
                <span style={{fontSize:'10px'}} className="duration">{Math.floor(totalDuration / 60)} hr {totalDuration % 60} mins</span>
                </p>
            </div>

        
      </div>
      <div className="subtotal-container">
            <div className="subtotal">
                <p>Subtotal</p>
                <p className="price">R{totalCost.toFixed(2)}</p>
            </div>
            {showContinue && (
          <div>
            <button
              className="pay-with-card-button"
              onClick={handleShowContinueOptions}
              style={{ width: '100%', marginBottom: '2px' , display: "none"}}
            >
              Pay with card
            </button>
            <button className="pay-with-card-button" onClick={handleCashPayment} style={{ width: '100%' }}>
              Pay with cash
            </button>
          </div>
        )}
        </div>
        {showContinueOptions && (
          <div>
            <form action="https://sandbox.payfast.co.za/eng/process" method="post">
              <div className="form-input-container">
                <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  required
                  onChange={(e) => setFirstName(e.target.value)}
                  className="form-input"
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  required
                  onChange={(e) => setLastName(e.target.value)}
                  className="form-input"
                />
              </div>
              <input
                type="email"
                placeholder="Email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                className="form-input"
              />
              <div className="phone-input-container">
                <img src={sa} alt="Flag" />
                <input
                  type="tel"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  required
                    maxLength="10"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="phone-input"
                />
              </div>
              <input type="hidden" name="merchant_id" value="10027533" />
              <input type="hidden" name="merchant_key" value="52ccou97s4pw9" />
              <input type="hidden" name="amount" value={totalCost.toFixed(2)} />
              <input type="hidden" name="item_name" value="Test Product" />
              <input
                type="hidden"
                name="return_url"
                value={`https://snyp.co.za/success?email=${encodeURIComponent(email)}&phone=${encodeURIComponent(
                  phoneNumber
                )}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(
                  lastName
                )}&businessName=${encodeURIComponent(businessName)}&businessId=${encodeURIComponent(
                  businessId
                )}&selectedDate=${encodeURIComponent(selectedDay.date)}&selectedTime=${encodeURIComponent(
                  selectedSlot
                )}&professionalName=${encodeURIComponent(professionalDetails.name)}&professionalEmail=${encodeURIComponent(
                  professionalEmail
                )}&totalCost=${encodeURIComponent(totalCost.toFixed(2))}&professionalId=${encodeURIComponent(
                  professionalId
                )}&totalDuration=${encodeURIComponent(totalDuration)}&cart=${encodeURIComponent(
                  JSON.stringify(cart)
                )}&paymentType=card`}
              />
              <input type="hidden" name="cancel_url" value="https://www.example.com/cancel" />
              <input
                className="pay-with-card-button"
                type="submit"
                value="Card payment"
                disabled={!email || !phoneNumber || !firstName || !lastName}
              />
            </form>
          </div>
        )}

        {showCashForm && (
          <div>
            <form onSubmit={handleCashFormSubmit}>
              <div className="form-input-container">
                <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  required
                  onChange={(e) => setFirstName(e.target.value)}
                  className="form-input"
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  required
                  onChange={(e) => setLastName(e.target.value)}
                  className="form-input"
                />
              </div>
              <input
                type="email"
                placeholder="Email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                className="form-input"
              />
              <div className="phone-input-container">
                <img src={sa} alt="Flag" />
                <input
                  type="tel"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  required
                    maxLength="10"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="phone-input"
                />
              </div>
              <input className="pay-with-card-button" required type="submit" value="Cash payment" />
            </form>
          </div>
        )}
      </div>

      {/* Mobile Cart */}
      {cart.length > 0 && (
        <div className="cart-summary-mobileService" onClick={() => setShowModal(true)}>
          <p>View order</p>
          <p>R{totalCost.toFixed(2)}</p>
        </div>
      )}

      {/* Modal for Mobile Cart */}
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <div className="modal-header">
            <h3>
              Your order<p className="cart-item-business">{businessName}</p>
            </h3>
            <button className="close-buttonService" onClick={() => setShowModal(false)}>
              ×
            </button>
          </div>
          <div className="cart-details">
            <div className="profile-info">
              {professionalImage && (
                <img src={professionalImage} alt={professionalName} className="profile-pic" />
              )}
              <p className="cart-item-name">{professionalName}</p>
            </div>
            <div className="selectedserviceMobile">
            {cart.slice(1, 5).map((item) => (
        <div className="additional-services" key={item.serviceid}>
                <p style={{fontSize:'13px'}}>+ {item.name} 
                  <span className="price">R{item.price}</span>
                </p>
        </div>    
      ))}
              <div className="additional-services">
                <p  >{selectedDay ? `${moment(selectedDay.date).format('MMM Do')} at ${moment(selectedSlot, 'HH:mm').format('h:mmA')}`: ''} 
                <span  className="duration">{Math.floor(totalDuration / 60)} hr {totalDuration % 60} mins</span>
                </p>
            </div>
            <div className="subtotal-container">
            <div className="subtotal">
                <p>Subtotal</p>
                <p className="price">R{totalCost.toFixed(2)}</p>
            </div>
            {showContinue && (
          <div>
            {showContinue && (
                <>
                <button
                  className="pay-with-card-button"
                  disabled
                  onClick={handleShowContinueOptions}
                  style={{ background:'grey', padding: '10px 20px', fontSize: '16px', cursor: 'pointer', marginRight: '10px', display:"none" }}
                >
                  Pay with card 
                </button>
                <button
                  className="pay-with-card-button"
                  onClick={handleCashPayment}
                  style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}
                >
                  Cash payment 
                </button>
              </>
              
              )}
          </div>
        )}
        </div>
             
              {showContinueOptions && (
                <div>
                  <form action="https://sandbox.payfast.co.za/eng/process" method="post">
                    <div className="form-input-container">
                      <input
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        required
                        onChange={(e) => setFirstName(e.target.value)}
                        className="form-input"
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        required
                        onChange={(e) => setLastName(e.target.value)}
                        className="form-input"
                      />
                    </div>
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-input"
                    />
                    <div className="phone-input-container">
                      <img src={sa} alt="Flag" />
                      <input
                        type="tel"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        required
                          maxLength="10"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="phone-input"
                      />
                    </div>
                    <input type="hidden" name="merchant_id" value="10027533" />
                    <input type="hidden" name="merchant_key" value="52ccou97s4pw9" />
                    <input type="hidden" name="amount" value={totalCost.toFixed(2)} />
                    <input type="hidden" name="item_name" value="Test Product" />
                    <input
                      type="hidden"
                      name="return_url"
                      value={`https://snyp.co.za/success?email=${encodeURIComponent(email)}&phone=${encodeURIComponent(
                        phoneNumber
                      )}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(
                        lastName
                      )}&businessName=${encodeURIComponent(businessName)}&businessId=${encodeURIComponent(
                        businessId
                      )}&selectedDate=${encodeURIComponent(selectedDay.date)}&selectedTime=${encodeURIComponent(
                        selectedSlot
                      )}&professionalName=${encodeURIComponent(professionalDetails.name)}&professionalEmail=${encodeURIComponent(
                        professionalEmail
                      )}&totalCost=${encodeURIComponent(totalCost.toFixed(2))}&professionalId=${encodeURIComponent(
                        professionalId
                      )}&totalDuration=${encodeURIComponent(totalDuration)}&cart=${encodeURIComponent(
                        JSON.stringify(cart)
                      )}&paymentType=card`}
                    />
                    <input type="hidden" name="cancel_url" value="https://www.example.com/cancel" />
                    <input
                      style={{
                        background: '#000',
                        color: '#fff',
                        padding: '11px',
                        marginTop: '20px',
                      }}
                      type="submit"
                      value="Card M payment"
                      disabled={!email || !phoneNumber || !firstName || !lastName}
                    />
                  </form>
                </div>
              )}
              {showCashForm && (
                <div>
                  <form onSubmit={handleCashFormSubmit}>
                    <div className="form-input-container">
                      <input
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        required
                        onChange={(e) => setFirstName(e.target.value)}
                        className="form-input"
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        required
                        onChange={(e) => setLastName(e.target.value)}
                        className="form-input"
                      />
                    </div>
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-input"
                    />
                    <div className="phone-input-container">
                      <img src={sa} alt="Flag" />
                      <input
                        type="tel"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        required
                          maxLength="10"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="phone-input"
                      />
                    </div>
                    <button
                      style={{
                        background: '#fff',
                        color: '#000',
                        padding: '11px',
                        marginTop: '20px',
                        border: ' 1px solid #000',
                      }}
                      type="submit"
                    >
                      Pay when you get there
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Schedule;
